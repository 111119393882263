<svelte:options accessors />

<script
	lang="ts"
	context="module"
>
	import type { MediatorProviders } from 'types/mediator'
	export interface VehicleMediatorProviders extends MediatorProviders {
		saveVehicle: () => void
		vehicleChanged: (hasChanged: boolean) => void
		requiredVehicleFieldsComplete: (requiredFieldsComplete: boolean) => void
	}
</script>

<script lang="ts">
	import type { Vehicle } from './basic'
	import type { Mediator } from 'client/types/common'
	import type { VehicleBasicStageOne$result, inventoryTypeDetail$result, vehicleInventorySettingValues$result, CreateVehicle$input, InventoryOptionValueUpdate } from '$houdini'
	import type { InventoryType, Manufacturer, Model, Category, UserAccount, UserStatus, InventoryLocation } from 'utility/search-fields'
	import type { SvelteAsr } from 'types/common'
	import type { FlatQuestion, OptionValueMap } from 'utility/load-part'
	type SettingValues = vehicleInventorySettingValues$result['settingValues']

	//#region Svelte Components
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import QaList from '../../part/QaList.svelte'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import TextArea from '@isoftdata/svelte-textarea'
	import YearInput from '@isoftdata/svelte-year-input'
	import DateInput from '@isoftdata/svelte-date-input'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import VinLookupModal from 'components/VinLookupModal.svelte'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import DuplicateVinModal from 'components/DuplicateVinModal.svelte'
	import PartMarketingFields from 'components/PartMarketingFields.svelte'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	//#endregion

	//#region Utility Imports
	import { klona } from 'klona'
	import { dequal } from 'dequal'
	import { graphql } from '$houdini'
	import session from 'stores/session'
	import { parseISO, formatISO } from 'date-fns'
	import { onMount, tick, getContext, onDestroy } from 'svelte'
	import { hasPermission } from 'utility/permission'
	import vehicleStatuses from 'common/vehicle-statuses'
	import { getEventValue } from '@isoftdata/browser-event'
	import userLocalWritable from 'stores/user-local-writable'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import { decodeVin as fetchDecodedVin } from 'utility/vin-decode'
	import { getOptionValueMap, computeTypeFields } from 'utility/load-part'
	import { loadPartModelsForInventoryTypeAndManufacturer } from 'utility/search-fields'
	//#endregion

	//@region Exported Component Props
	export let asr: SvelteAsr
	export let vehicle: Vehicle
	export let originalVehicle: Vehicle
	export let locations: string[] = []
	export let vehicleModels: Model[] = []
	export let categories: Category[] = []
	export let options: FlatQuestion[] = []
	export let colors: {
		vehicleColorCodes: VehicleBasicStageOne$result['vehicleColorCodes']
		vehicleColors: VehicleBasicStageOne$result['vehicleColors']
	}
	export let unitTypes: InventoryType[] = []
	export let userStatuses: UserStatus[] = []
	export let userAccounts: UserAccount[] = []
	export let mediator = getContext<Mediator<VehicleMediatorProviders>>('mediator')
	export let inventoryConditions: string[] = []
	export let states: VehicleBasicStageOne$result['states'] = []
	export let vehicleManufacturers: Manufacturer[] = []
	export let saleClasses: VehicleBasicStageOne$result['saleClasses'] = []
	export let glCategories: VehicleBasicStageOne$result['glCategories'] = []
	export let settingValues: vehicleInventorySettingValues$result['settingValues']
	export let vehicleChanged: boolean = false
	//#endregion

	//#region Local Variables
	let optionValueMap = getOptionValueMap({ inventoryOptions: options, serialized: false, serials: [] })
	let vinLoading: boolean = false
	let isLoadingInventoryTypeDetailManufacturersCategories: boolean = false
	let isLoadingModels: boolean = false
	let requiredFieldsComplete: boolean = false
	let vinValidationError: string = ''
	let showDuplicateVinModal: boolean = false
	let inventoryTypeWithDetail: inventoryTypeDetail$result['inventoryType']
	let qaList: QaList | undefined = undefined
	let sellerContact: string = vehicle.sellerContact ?? ''
	let sellerContactLastName: string = vehicle.sellerContact?.split(',')[0] ?? ''
	let sellerContactFirstName: string = vehicle.sellerContact?.split(',')[1] ?? ''
	let vinLookupModal: VinLookupModal
	let duplicateVinModal: DuplicateVinModal
	let qaChanged: boolean = false
	//#endregion

	//#region Permissions
	let canEditBasic = hasPermission('VEHICLES_CAN_EDIT_BASIC')
	let canEditAdditionalInfo = hasPermission('VEHICLES_CAN_EDIT_ADDITIONAL_INFORMATION')
	let canEditStockNumber = hasPermission('VEHICLES_CAN_EDIT_STOCK_NUMBER')
	let canEditStatus = hasPermission('VEHICLES_CAN_EDIT_STATUS')
	let canEditGlCategory = hasPermission('VEHICLES_CAN_EDIT_GL_CATEGORY')
	let canEditWholeUnits = hasPermission('VEHICLES_CAN_EDIT_WHOLE_UNITS')
	// let canEditWholeUnitAdvanced = hasPermission('VEHICLES_CAN_EDIT_WHOLE_UNIT_ADVANCED')
	// let canEditQanda = hasPermission('VEHICLES_CAN_EDIT_QANDA')
	// let canEditBids = hasPermission('VEHICLES_CAN_EDIT_BIDS')
	// let canEditNonBidUnits = hasPermission('VEHICLES_CAN_EDIT_NON_BID_UNITS')
	// let canViewVehiclePerformance = hasPermission('VEHICLES_CAN_VIEW_VEHICLE_PERFORMANCE')
	// let canChangeStockQuantity = hasPermission('VEHICLES_CAN_CHANGE_STOCK_QUANTITY')
	//#endregion

	const statesByCountry = states.reduce((acc, state) => {
		if (!state.country) {
			return acc
		}

		if (!acc[state.country]) {
			acc[state.country] = []
		}
		acc[state.country].push(state)
		return acc
	}, {})

	const unitTypeDetailQuery = graphql(`
		query inventoryTypeDetail($inventoryTypeId: Int!) {
			inventoryType(id: $inventoryTypeId) {
				typeData1History
				typeData2History
				typeData3History
				typeData4History
				typeLabel1
				typeLabel2
				typeLabel3
				typeLabel4
				vehicle
				requireSerialization
				typeSetId: setId
				name
				inventoryTypeId: id
				categories {
					id
					description
					name
				}
				partManufacturers: manufacturers {
					id
					name
					code
				}
			}
		}
	`)

	const duplicateVinCheckQuery = graphql(`
		query duplicateVinQuery($filter: VehicleFilter) {
			vehicles(filter: $filter) {
				items {
					id
					store {
						name
					}
					stockNumber
					enteredDate
					status
					userStatus
					inventoryType {
						id
						name
					}
					location
				}
			}
		}
	`)

	//The only fields we need to get back are what we need to pass to asr.go
	const createVehicleMutation = graphql(`
		mutation CreateVehicle($input: NewVehicle!) {
			createVehicle(input: $input) {
				id
				storeId
				lastChangedDate
			}
		}
	`)

	const updateVehicleMutation = graphql(`
		mutation UpdateVehicle($input: VehicleUpdate!) {
			updateVehicle(input: $input) {
				id
				storeId
				lastChangedDate
			}
		}
	`)

	const cardsOpen = userLocalWritable($session.id, 'vehicleScreenCardsOpen', {
		basic: true,
		salesMarketing: true,
		purchaseSeller: true,
		title: true,
		insurance: true,
	})

	async function checkForDuplicateVin(vin: string) {
		if (vin) {
			vin = vin.trim()
			let vehiclesWithSameVin = (await duplicateVinCheckQuery.fetch({ variables: { filter: { vin } } }))?.data?.vehicles?.items ?? []
			if (vehiclesWithSameVin.length > 0 && vehicle.id) {
				//Be sure to ignore the vehicle the user is currently editing
				vehiclesWithSameVin = vehiclesWithSameVin.filter(vehicle => vehicle.id !== vehicle.id)
			}
			if (vehiclesWithSameVin.length > 0) {
				//Let the user decide what to do since there is at least one existing vehicle with this same VIN
				await duplicateVinModal.open(vehiclesWithSameVin, vin)
			}
			return vehiclesWithSameVin
		} else {
			return []
		}
	}

	async function decodeVin(vin: string) {
		vin = vin.trim()
		if (vin) {
			try {
				vinLoading = true
				const decodedVin = await fetchDecodedVin(vin)
				if (decodedVin?.validation?.isValid) {
					vehicle.year = decodedVin.year ?? vehicle.year

					const decodedVinManufacturer = decodedVin.make?.toUpperCase()
					const vehicleManufacturerMatch = decodedVinManufacturer ? vehicleManufacturers.find(make => make.name.toUpperCase() === decodedVinManufacturer) : undefined
					if (vehicleManufacturerMatch) {
						vehicle.manufacturer = vehicleManufacturerMatch
					}

					await loadAndSetModels(vehicle.wholeUnit.inventoryTypeId, vehicle.manufacturer?.id)
					if (vehicleManufacturerMatch && decodedVin.model) {
						const decodedVinModel = decodedVin.model.toUpperCase()
						const vehicleModelMatch = vehicleModels.find(model => model.name.toUpperCase() === decodedVinModel)
						vehicle.model = vehicleModelMatch ?? vehicle.model
					}

					if (vehicle.notes && vehicle.notes.trim().length > 0 && decodedVin?.notes) {
						vehicle.notes += '\n\n' + decodedVin.notes
					} else if (decodedVin.notes) {
						vehicle.notes = decodedVin.notes
					}
				}
				vinValidationError = decodedVin?.validation?.isValid ? '' : decodedVin?.validation?.message ?? ''
			} catch (err) {
				console.error(err)
				vinValidationError = 'Error decoding VIN'
			} finally {
				vinLoading = false
			}
		}
	}

	function transformInventoryOptionValueForMutation(optionValue: string | number | boolean | null) {
		if (typeof optionValue === 'number') {
			return optionValue.toString()
		} else if (typeof optionValue === 'boolean') {
			return booleanToString(optionValue)
		} else if (optionValue === null) {
			return ''
		} else {
			return optionValue
		}
	}

	const isValidYear = (year: string | number) => typeof year === 'number' && year.toString().length === 4

	async function saveVehicle(vehicle: Vehicle, optionValueMap: OptionValueMap) {
		const input: Omit<CreateVehicle$input['input'], 'inventoryTypeId' | 'storeId' | 'id'> = {
			adjuster: vehicle.adjuster,
			bodyStyle: vehicle?.bodyStyle,
			category: vehicle?.categoryType?.name ?? '',
			claimNumber: vehicle?.claimNumber,
			condition: vehicle.wholeUnit.condition,
			description: vehicle?.description,
			dismantled: vehicle?.dismantled,
			dismantler: vehicle.dismantler?.id,
			externalColor: vehicle?.externalColor,
			externalColorCode: vehicle?.externalColorCode,
			fileNumber: vehicle.fileNumber,
			glCategoryId: vehicle.glCategory?.id,
			internalColor: vehicle?.internalColor,
			internalColorCode: vehicle?.internalColorCode,
			inventoryGlCategoryId: vehicle.inventoryGlCategory?.id,
			jobberPrice: vehicle?.wholeUnit?.jobberPrice,
			junkingNumber: vehicle?.junkingNumber,
			listPrice: vehicle?.wholeUnit?.listPrice,
			location: vehicle?.location,
			make: vehicle?.manufacturer?.name ?? '',
			mileage: vehicle?.mileage, //Odometer
			model: vehicle?.model?.name ?? '',
			notes: vehicle?.notes, //Internal Notes
			pickUpLocation: vehicle?.pickUpLocation,
			public: vehicle.public, //Show Online
			purchaseDate: vehicle?.purchaseDate,
			purchasedFrom: vehicle?.purchasedFrom,
			receivedDate: vehicle?.receivedDate,
			retailPrice: vehicle?.wholeUnit?.retailPrice,
			saleClass: {
				code: vehicle?.wholeUnit?.saleClass?.code,
			},
			sellerContact: vehicle?.sellerContact,
			sellerInfo: vehicle?.sellerInfo,
			sellerPhone: vehicle?.sellerPhone,
			status: vehicle?.status,
			stockNumber: vehicle?.stockNumber,
			taxable: vehicle.taxable,
			titleNumber: vehicle?.titleNumber,
			titleReceivedDate: vehicle?.titleReceivedDate,
			titleState: vehicle?.titleState,
			titleStatus: vehicle?.titleStatus,
			titleType: vehicle?.titleType,
			userStatus: vehicle?.userStatus,
			vin: vehicle?.vin,
			wholesalePrice: vehicle?.wholeUnit?.wholesalePrice,
			year: vehicle?.year,
			optionValues: Array.from(optionValueMap.entries()).reduce((acc: InventoryOptionValueUpdate[], [key, value]) => {
				if (key.optionId) {
					acc.push({
						inventoryOptionId: key.optionId,
						value: transformInventoryOptionValueForMutation(value),
					})
				}
				return acc
			}, []),
		}

		try {
			if (vehicle.id) {
				const res = await updateVehicleMutation.mutate({
					input: {
						...input,
						id: vehicle.id,
					},
				})
				originalVehicle = vehicle
				qaChanged = false
				if (res.data?.updateVehicle?.id) {
					asr.go(null, { vehicleId: res.data.updateVehicle.id, storeId: res.data.updateVehicle.storeId, lastResetTime: Date.now() })
				}
			} else {
				const res = await createVehicleMutation.mutate({
					input: {
						...input,
						inventoryTypeId: vehicle.wholeUnit.inventoryTypeId,
						storeId: vehicle.store.id,
					},
				})
				originalVehicle = vehicle
				qaChanged = false
				if (res.data?.createVehicle?.id) {
					asr.go(null, { vehicleId: res.data.createVehicle.id, storeId: res.data.createVehicle.storeId, lastResetTime: Date.now() })
				}
			}

			mediator.publish('showMessage', {
				type: 'success',
				heading: 'Saved!',
				message: 'Vehicle saved successfully.',
				time: 10,
			})
		} catch (err) {
			mediator.publish('showMessage', {
				type: 'danger',
				heading: 'Error!',
				message: Array.isArray(err) ? err[0].message : 'An unknown error occurred',
				time: 10,
			})
		}
	}

	const saveVehicleUnsubscribe = mediator.provide('saveVehicle', () => saveVehicle(vehicle, optionValueMap))

	const splitString = (string: string | null): [string, string] => {
		if (string === null) return ['', '']
		const array = string?.split(' ')?.map(part => part?.replace(/[^a-zA-Z0-9]/g, ''))
		return [array?.[0] ?? '', array?.[1] ?? '']
	}

	function getRequiredFieldsComplete(vehicle: Vehicle | undefined, originalVehicle: Vehicle | undefined, settingValues?: SettingValues) {
		const requiredFieldKeys = ['year', 'manufacturer', 'model', 'categoryType']
		for (const key of requiredFieldKeys) {
			if ((!vehicle?.id || !dequal(vehicle[key], originalVehicle?.[key])) && !vehicle?.[key]) {
				return false
			}
		}

		if (vehicle?.year && !isValidYear(vehicle.year)) {
			return false
		}

		if (settingValues?.vehicle?.mileageRequired && (!vehicle?.id || vehicle?.mileage !== originalVehicle?.mileage) && !vehicle?.mileage) {
			return false
		}
		if (settingValues?.vehicle?.minVinLength && (!vehicle?.id || vehicle?.vin !== originalVehicle?.vin) && vehicle?.vin && vehicle?.vin.length < settingValues.vehicle?.minVinLength) {
			return false
		}

		return true
	}

	async function loadAndSetModels(wholeUnitTypeId: number, manufacturerId?: number) {
		try {
			if (manufacturerId) {
				isLoadingModels = true
				vehicleModels = await loadPartModelsForInventoryTypeAndManufacturer({ inventoryTypeId: wholeUnitTypeId, manufacturerId })
			} else {
				vehicleModels = []
			}
		} finally {
			isLoadingModels = false
		}
	}

	const getSellerContactFromIndividual = (lastName: string, firstName: string) => [lastName, firstName].filter(v => v).join(', ')
	const getIndividualFromSellerContact = (sellerContact: string) => {
		const [lastName, firstName] = splitString(sellerContact)
		return { lastName, firstName }
	}

	function setSellerContact(isCompany: boolean) {
		if (isCompany) {
			sellerContact = getSellerContactFromIndividual(sellerContactLastName, sellerContactFirstName)
			vehicle.sellerContact = sellerContact
		} else {
			const { firstName, lastName } = getIndividualFromSellerContact(sellerContact)
			sellerContactLastName = lastName
			sellerContactFirstName = firstName
			vehicle.sellerContact = sellerContact
		}
	}

	async function loadInventoryTypeDetailManufacturersCategories(inventoryTypeId: number | null) {
		if (inventoryTypeId) {
			isLoadingInventoryTypeDetailManufacturersCategories = true
			try {
				const res = await unitTypeDetailQuery.fetch({ variables: { inventoryTypeId } })

				if (res.data?.inventoryType) {
					inventoryTypeWithDetail = res.data.inventoryType
					vehicleManufacturers = res.data.inventoryType.partManufacturers ?? []
					categories = res.data.inventoryType.categories ?? []
				}
			} finally {
				isLoadingInventoryTypeDetailManufacturersCategories = false
			}
		}
	}

	//#region Reactive statements
	$: requiredFieldsComplete = getRequiredFieldsComplete(vehicle, originalVehicle)
	$: canEditBasic = hasPermission('VEHICLES_CAN_EDIT_BASIC', vehicle.store.id)
	$: canEditAdditionalInfo = hasPermission('VEHICLES_CAN_EDIT_ADDITIONAL_INFORMATION', vehicle.store.id)
	$: canEditStockNumber = hasPermission('VEHICLES_CAN_EDIT_STOCK_NUMBER', vehicle.store.id)
	$: canEditStatus = hasPermission('VEHICLES_CAN_EDIT_STATUS', vehicle.store.id)
	$: canEditGlCategory = hasPermission('VEHICLES_CAN_EDIT_GL_CATEGORY', vehicle.store.id)
	$: canEditWholeUnits = hasPermission('VEHICLES_CAN_EDIT_WHOLE_UNITS', vehicle.store.id)
	$: vehicleChanged = qaChanged || !dequal(vehicle, originalVehicle)
	$: mediator.publish('vehicleChanged', vehicleChanged)
	$: mediator.publish('requiredVehicleFieldsComplete', requiredFieldsComplete)
	//#endregion

	onMount(async () => {
		window.scrollTo(0, 0)
		if (!vehicle.id) {
			await tick()
			document.getElementById('vinInput')?.focus()
		}

		loadInventoryTypeDetailManufacturersCategories(vehicle.wholeUnit.inventoryTypeId).catch(err => console.error(err))
	})

	onDestroy(() => {
		saveVehicleUnsubscribe?.()
	})
</script>

<fieldset disabled={!canEditBasic}>
	<CollapsibleCard
		headerText="Basic Info"
		cardHeaderClass="card-header d-flex justify-content-between h4"
		entireHeaderToggles
		bind:bodyShown={$cardsOpen.basic}
	>
		<div class="row">
			{#if vehicle.topImage}
				<div class="col-12 col-md-2 d-flex justify-content-center">
					<a
						target="_blank"
						href={vehicle.topImage.url}
						style="height: min-content;"
					>
						<img
							class="img-fluid rounded img-thumbnail p-0"
							src="{vehicle.topImage.url}?height=240&width=240&fit=contain"
							title={vehicle.topImage.name}
							alt={`Stock #${vehicle.stockNumber}`}
						/>
					</a>
				</div>
			{/if}
			<div
				class="col-12"
				class:col-md-8={vehicle.topImage}
				class:col-md-10={!vehicle.topImage}
				class:col-lg-7={vehicle.topImage}
				class:col-lg-9={!vehicle.topImage}
			>
				<div class="form-row">
					<div class="col-sm-12 col-md-4">
						<Input
							label="Stock #"
							bind:value={vehicle.stockNumber}
							placeholder="Leave blank to auto-generate on save"
							title="(Optional) enter a unique stock # for this vehicle unit. If left blank, one will automatically be assigned."
							disabled={!canEditStockNumber}
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<Input
							label="VIN"
							id="vinInput"
							title="The VIN from the unit. If installed, the VIN decoder system will attempt to decode this value."
							bind:value={vehicle.vin}
							on:change={async event => {
								const vin = getEventValue(event)
								vinLoading = true
								const duplicates = await checkForDuplicateVin(vin)
								if (duplicates.length === 0) {
									await decodeVin(vin)
								}
								vinLoading = false
							}}
							class="form-control form-control-sm"
							placeholder="Vehicle Identification Number"
							disabled={vinLoading}
							required={!!settingValues.vehicle.minVinLength && settingValues.vehicle.minVinLength > 0}
							hint={vehicle.vin ? vinValidationError : ''}
							hintClass={vehicle.vin && vinValidationError ? 'text-danger' : ''}
							validation={{
								validator: vin => {
									return !!(settingValues.vehicle.minVinLength, vin && typeof vin === 'string' && vin.length >= settingValues.vehicle.minVinLength)
								},
								value: vehicle?.vin,
							}}
						>
							<Button
								slot="append"
								class="btn-sm btn-outline-primary"
								iconClass="magnifying-glass"
								isLoading={vinLoading}
								title="View VIN decode information and/or enter a new VIN."
								on:click={() => vinLookupModal.open(vehicle.vin ?? '')}
							/>
						</Input>
					</div>
					<div class="col-sm-12 col-md-4">
						<Select
							label="Type"
							required
							showEmptyOption={false}
							bind:value={vehicle.wholeUnit.inventoryTypeId}
							disabled={!!vehicle?.id}
							title="(Required) choose the unit type. This controls the Q&A available, the categories, and some other information."
							on:change={event => {
								const typeId = parseInt(getEventValue(event), 10)
								if (typeId) {
									loadInventoryTypeDetailManufacturersCategories(typeId)
								}
							}}
						>
							{#each unitTypes as { id, name }}
								<option value={id}>{name}</option>
							{/each}
						</Select>
					</div>

					<div class="col-sm-12 col-md-4">
						<YearInput
							required
							label="Year"
							type="number"
							value={vehicle.year}
							isLoading={vinLoading}
							on:change={event => (vehicle.year = event.detail)}
							validation={{
								validator: isValidYear,
								value: vehicle?.year,
							}}
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<Autocomplete
							required
							label="Make"
							options={vehicleManufacturers}
							value={vehicle.manufacturer}
							getLabel={option => option?.name ?? ''}
							disabled={vinLoading}
							isLoading={isLoadingInventoryTypeDetailManufacturersCategories}
							title="Year of manufacture"
							on:change={async ({ detail: selectedManufacturer }) => {
								vehicle.manufacturer = selectedManufacturer
								let previousVehicleModel = klona(vehicle.model)
								if (vehicle.wholeUnit.inventoryTypeId && vehicle.manufacturer?.id) {
									await loadAndSetModels(vehicle.wholeUnit.inventoryTypeId, vehicle.manufacturer?.id)
								} else {
									vehicleModels = []
								}
								if (previousVehicleModel && !vehicleModels.find(model => model.id === previousVehicleModel.id)) {
									vehicle.model = null
								}
							}}
						/>
					</div>

					<div class="col-sm-12 col-md-4">
						<Autocomplete
							required
							label="Model"
							isLoading={isLoadingModels}
							bind:value={vehicle.model}
							options={vehicleModels}
							getLabel={option => option?.name ?? ''}
							disabled={vinLoading}
							title="The model of this unit"
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<Select
							label="Status"
							bind:value={vehicle.status}
							disabled={!canEditStatus}
							title="Current vehicle status. Key:&#013;B (Bid): a bid has been submitted, but not awarded.&#013;A (Available): the unit has been received.&#013;H (On Hold): the unit is received but currently on hold.&#013;S (Sold): the unit has been sold.&#013;C (Crushed): the unit has been crushed/scrapped.&#013;D (Deleted): this unit was a duplicate or mistake. Do not use this for vehicles that have been dismantled, use Crushed instead."
						>
							{#each vehicleStatuses as { abbreviation, name }}
								<option
									value={abbreviation}
									disabled={abbreviation === 'B' && vehicle.status !== 'B'}>{abbreviation} - {name}</option
								>
							{/each}
						</Select>
					</div>
					<div class="col-sm-12 col-md-4">
						<Select
							label="User Status"
							bind:value={vehicle.userStatus}
							disabled={!canEditStatus}
							title="Custom company status (if any)."
						>
							{#each userStatuses as { status, description }}
								<option value={status}>{status} - {description}</option>
							{/each}
						</Select>
					</div>
					<div class="col-sm-12 col-md-4">
						<Autocomplete
							label="Location"
							bind:value={vehicle.location}
							title="Location of the unit in the yard"
							options={locations}
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<datalist id="vehicle-color-codes">
							{#each colors.vehicleColorCodes as name}
								<option value={name}></option>
							{/each}
						</datalist>
						<datalist id="vehicle-colors">
							{#each colors.vehicleColors as name}
								<option value={name}></option>
							{/each}
						</datalist>
						<label
							for="exterior-vehicle-color"
							class="col-form-label">Exterior Color/Code</label
						>
						<div class="input-group input-group-sm">
							<input
								id="exterior-vehicle-color"
								list="vehicle-colors"
								placeholder="Color"
								aria-label="exterior color"
								class="form-control"
								title="The exterior color"
								bind:value={vehicle.externalColor}
							/>
							<input
								list="vehicle-color-codes"
								placeholder="Color Code"
								aria-label="exterior color code"
								class="form-control"
								title="A paint code for the exterior color of the unit."
								bind:value={vehicle.externalColorCode}
							/>
						</div>
					</div>
					<div class="col-sm-12 col-md-4">
						<label
							for="internal-vehicle-color"
							class="col-form-label">Internal Color/Code</label
						>
						<div class="input-group input-group-sm">
							<input
								id="internal-vehicle-color"
								list="vehicle-colors"
								placeholder="Color"
								aria-label="internal color"
								class="form-control"
								title="The interior color"
								bind:value={vehicle.internalColor}
							/>
							<input
								list="vehicle-color-codes"
								placeholder="Color Code"
								aria-label="internal color code"
								class="form-control"
								title="A paint code for the interior color of the unit."
								bind:value={vehicle.internalColorCode}
							/>
						</div>
					</div>
					<div class="col-sm-12 col-md-4">
						<Input
							label="Body Style"
							title="General body style of the unit."
							bind:value={vehicle.bodyStyle}
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<Input
							type="number"
							bind:value={vehicle.mileage}
							label="Odometer"
							title="Current odometer reading."
							required={settingValues.vehicle.mileageRequired}
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<div class="label-padding">
							<Checkbox
								label="Dismantled"
								bind:checked={vehicle.dismantled}
							/>
						</div>
						<Autocomplete
							label="Dismantler"
							showLabel={false}
							options={userAccounts}
							title="The technician that dismantled the unit."
							bind:value={vehicle.dismantler}
							getLabel={option => option?.name ?? ''}
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<DateInput
							label="Dismantle Date"
							allowNone
							value={vehicle.dismantleDate ? formatISO(vehicle.dismantleDate, { representation: 'date' }) : null}
							title="For a unit that is being dismantled into components, when the unit completed the dismantle process."
							on:change={event => {
								const value = getEventValue(event)
								vehicle.dismantleDate = value ? parseISO(value) : null
							}}
							on:allowDateChange={event => {
								if (!event.detail) {
									vehicle.dismantleDate = null
								}
							}}
						/>
					</div>

					<div class="col-sm-12 col-md-4">
						<SiteAutocomplete
							label="Store"
							bind:value={vehicle.store}
							options={$session.authorizedStores}
							showEmptyOption={false}
							disabled={!!vehicle.id}
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<Autocomplete
							label="Vehicle GL Category"
							options={glCategories}
							bind:value={vehicle.glCategory}
							getLabel={option => option?.name ?? ''}
							disabled={!canEditGlCategory}
							title="The accounting category for this unit. This will control what accounts are affected if this vehicle is sold outright."
						/>
					</div>
					<div class="col-sm-12 col-md-4">
						<Autocomplete
							label="Parts GL Category"
							options={glCategories}
							bind:value={vehicle.inventoryGlCategory}
							getLabel={option => option?.name ?? ''}
							disabled={!canEditGlCategory}
							title="The accounting category for inventory dismantled from this unit. This will control what accounts are affected if components from this vehicle are consumed or sold."
						/>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-2 col-lg-3 pl-md-0">
				<TextArea
					labelParentClass="h-100 d-flex flex-column"
					label="Internal Notes"
					styleClass="col-sm-6"
					placeholder="Internal notes won't appear on any customer-facing reports or online."
					bind:value={vehicle.notes}
					class="flex-grow-1"
					disabled={vinLoading}
					title="Internal notes about this unit."
				/>
			</div>
		</div>

		{#if vehicle.wholeUnit.inventoryTypeId && inventoryTypeWithDetail}
			<div class="card mt-3">
				<h5 class="card-header">Custom Fields</h5>
				<div class="card-body">
					<QaList
						inventoryTypeData={inventoryTypeWithDetail}
						category={vehicle.categoryType ?? null}
						typeFields={computeTypeFields(vehicle.wholeUnit)}
						{options}
						{optionValueMap}
						serials={[]}
						bind:this={qaList}
						on:typeFieldChange={event => {
							const { label, value } = event.detail
							if (vehicle.wholeUnit.typeField1?.label === label) {
								vehicle.wholeUnit.typeField1.data = value
							} else if (vehicle.wholeUnit.typeField2?.label === label) {
								vehicle.wholeUnit.typeField2.data = value
							} else if (vehicle.wholeUnit.typeField3?.label === label) {
								vehicle.wholeUnit.typeField3.data = value
							} else if (vehicle.wholeUnit.typeField4?.label === label) {
								vehicle.wholeUnit.typeField4.data = value
							}
						}}
						on:qaInputChange={event => {
							const option = event.detail
							if (option.value === null || option.value === '') {
								optionValueMap.delete({ optionId: option.id })
							} else {
								optionValueMap.set({ optionId: option.id }, option.value)
							}
							//Because the option values are stored in a separate map, we need to manually emit that the vehicle has changed
							qaChanged = true
						}}
					/>
				</div>
			</div>
		{/if}
	</CollapsibleCard>
</fieldset>

<!-- The marketing component handles permissions for these marketing fields -->
<fieldset>
	<CollapsibleCard
		headerText="Sales & Marketing"
		cardHeaderClass="card-header d-flex justify-content-between h4"
		entireHeaderToggles
		cardClass="mt-2"
		bind:bodyShown={$cardsOpen.salesMarketing}
	>
		<Checkbox
			label="Taxable"
			bind:checked={vehicle.taxable}
			title="Whether this unit will be marked as taxable on customer documents by default"
			inline
			disabled={!canEditBasic}
		/>
		<Checkbox
			label="Show Online"
			bind:checked={vehicle.public}
			title="If checked, any configured marketing exports are allowed to view this unit and advertise it"
			inline
			disabled={!canEditBasic}
		/>
		<!-- We don't currently support adjusting the in stock flag on web. Apparently it will be a bit of a lift on the API side, at least. -->
		<Checkbox
			label="Unit Available (In Stock)"
			checked={vehicle.inStock}
			disabled
			inline
		/>
		<!-- `originalVehicle` is a deep copy of `vehicle` -->
		<PartMarketingFields
			origPart={originalVehicle.wholeUnit}
			{settingValues}
			inventoryTypeCategories={categories}
			{saleClasses}
			{inventoryConditions}
			disableSkuFields={!canEditWholeUnits}
			disableGlobalFields={!canEditWholeUnits}
			manufacturerName={vehicle?.manufacturer?.name ?? ''}
			modelName={vehicle?.model?.name ?? ''}
			bind:part={vehicle.wholeUnit}
			{vehicle}
			entityContext="vehicle"
			on:categoryChange={async () => {
				if (qaList) {
					await qaList.fetchNewQuestions({
						categoryName: vehicle.wholeUnit.category?.name ?? '',
						manufacturerId: vehicle.manufacturer?.id ?? null,
						modelId: vehicle.model?.id ?? null,
						inventoryTypeId: vehicle.wholeUnit.inventoryTypeId,
					})
				}
			}}
		/>
	</CollapsibleCard>
</fieldset>

<fieldset disabled={!canEditAdditionalInfo}>
	<CollapsibleCard
		headerText="Seller & Purchasing"
		cardHeaderClass="card-header d-flex justify-content-between h4"
		entireHeaderToggles
		cardClass="mt-2"
		bind:bodyShown={$cardsOpen.purchaseSeller}
	>
		<div class="form-row align-items-end">
			<div class="col-sm-12 col-md-4">
				<Checkbox
					trueLabel="Company"
					falseLabel="Individual"
					type="radio"
					showLabel={false}
					bind:checked={vehicle.sellerIsCompany}
					on:change={event => {
						const isCompany = stringToBoolean(getEventValue(event))
						vehicle.sellerIsCompany = isCompany
						setSellerContact(isCompany)
					}}
				/>

				{#if vehicle.sellerIsCompany}
					<Input
						label="Seller Contact"
						value={sellerContact}
						on:change={event => {
							sellerContact = getEventValue(event)
							const { lastName, firstName } = getIndividualFromSellerContact(sellerContact)
							sellerContactLastName = lastName
							sellerContactFirstName = firstName
							vehicle.sellerContact = sellerContact
						}}
					/>
				{:else}
					<div class="form-group mb-1">
						<label
							for="sellerContactFirstName"
							class="col-form-label">Seller Contact</label
						>
						<div class="input-group">
							<input
								id="sellerContactLastName"
								aria-label="Last name"
								placeholder="Last Name"
								class="form-control form-control-sm"
								value={sellerContactLastName}
								on:change={event => {
									sellerContactLastName = getEventValue(event)
									sellerContact = getSellerContactFromIndividual(sellerContactLastName, sellerContactFirstName)
									vehicle.sellerContact = sellerContact
								}}
							/>
							<input
								id="sellerContactFirstName"
								aria-label="First name"
								placeholder="First Name"
								class="form-control form-control-sm"
								value={sellerContactFirstName}
								on:change={event => {
									sellerContactFirstName = getEventValue(event)
									sellerContact = getSellerContactFromIndividual(sellerContactLastName, sellerContactFirstName)
									vehicle.sellerContact = sellerContact
								}}
							/>
						</div>
					</div>
				{/if}
			</div>
			<div class="col-sm-12 col-md-4">
				<Input
					label="Seller Phone #"
					bind:value={vehicle.sellerPhone}
				/>
			</div>
			<div class="col-sm-12 col-md-6">
				<TextArea
					label="Seller Info"
					bind:value={vehicle.sellerPhone}
				/>
			</div>
			<div class="col-sm-12 col-md-6">
				<TextArea
					label="Pick-up Location"
					bind:value={vehicle.pickUpLocation}
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<DateInput
					label="Purchase Date"
					allowNone
					value={vehicle.purchaseDate ? formatISO(vehicle.purchaseDate, { representation: 'date' }) : null}
					on:change={event => {
						const value = getEventValue(event)
						vehicle.purchaseDate = value ? parseISO(value) : null
					}}
					on:allowDateChange={event => {
						if (!event.detail) {
							vehicle.purchaseDate = null
						}
					}}
					title="The date the unit was purchased. This will be set automatically when a vehicle bid is accepted."
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<Input
					label="Purchased From"
					bind:value={vehicle.purchasedFrom}
					title="Who the unit was purchased from. This will be set automatically when a vehicle bid is accepted."
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<DateInput
					label="Unit Received Date"
					allowNone
					value={vehicle.receivedDate ? formatISO(vehicle.receivedDate, { representation: 'date' }) : null}
					on:change={event => {
						const value = getEventValue(event)
						vehicle.receivedDate = value ? parseISO(value) : null
					}}
					on:allowDateChange={event => {
						if (!event.detail) {
							vehicle.receivedDate = null
						}
					}}
					title="When the unit was received from the seller and went through the inbounding process."
				/>
			</div>
		</div>
	</CollapsibleCard>

	<CollapsibleCard
		headerText="Title"
		cardHeaderClass="card-header d-flex justify-content-between h4"
		entireHeaderToggles
		cardClass="mt-2"
		bind:bodyShown={$cardsOpen.title}
	>
		<div class="form-row">
			<div class="col-sm-12 col-md-4">
				<DateInput
					label="Title Received"
					allowNone
					value={vehicle.titleReceivedDate ? formatISO(vehicle.titleReceivedDate, { representation: 'date' }) : null}
					on:change={event => {
						const value = getEventValue(event)
						vehicle.titleReceivedDate = value ? parseISO(value) : null
					}}
					on:allowDateChange={event => {
						if (!event.detail) {
							vehicle.titleReceivedDate = null
						}
					}}
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<Input
					label="Title #"
					bind:value={vehicle.titleNumber}
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<!-- <Select
					label="Title State"
					bind:value={vehicle.titleState}
				>
					{#each Object.keys(statesByCountry) as country}
						<optgroup label={country}>
							{#each statesByCountry[country] as state}
								<option value={state.stateAbbreviation}>{state.stateAbbreviation} - {state.stateName}</option>
							{/each}
						</optgroup>
					{/each}
				</Select> -->
			</div>
			<div class="col-sm-12 col-md-4">
				<Input
					label="Title Status"
					bind:value={vehicle.titleStatus}
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<Input
					label="Title Type"
					bind:value={vehicle.titleType}
				/>
			</div>
		</div>
	</CollapsibleCard>

	<CollapsibleCard
		headerText="Insurance"
		cardHeaderClass="card-header d-flex justify-content-between h4"
		entireHeaderToggles
		cardClass="mt-2"
		bind:bodyShown={$cardsOpen.insurance}
	>
		<div class="form-row">
			<div class="col-sm-12 col-md-4">
				<Input
					label="Adjuster"
					bind:value={vehicle.adjuster}
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<Input
					label="Claim #"
					bind:value={vehicle.claimNumber}
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<Input
					label="File #"
					bind:value={vehicle.fileNumber}
				/>
			</div>
			<div class="col-sm-12 col-md-4">
				<Input
					label="Junking #"
					bind:value={vehicle.junkingNumber}
				/>
			</div>
		</div></CollapsibleCard
	>
</fieldset>

<!-- In the future we might want to show the confirm button and do something different when it's pressed, like take the the VIN entered on the modal and put it on the vehicle record? -->
<VinLookupModal
	bind:this={vinLookupModal}
	on:confirm={async ({ detail: vin }) => {
		vinLoading = true
		console.log(vin)
		const duplicates = await checkForDuplicateVin(vin)
		if (duplicates.length === 0) {
			vehicle.vin = vin
			await decodeVin(vin)
		}
		vinLoading = false
	}}
/>
<DuplicateVinModal
	bind:this={duplicateVinModal}
	bind:show={showDuplicateVinModal}
	{asr}
	on:close={() => {
		vehicle.vin = ''
		showDuplicateVinModal = false
		document.getElementById('vinInput')?.focus()
	}}
	on:continueWithDuplicateVin={({ detail: vin }) => {
		vehicle.vin = vin
		decodeVin(vin)
		showDuplicateVinModal = false
	}}
/>

<style>
	.label-padding {
		padding-top: calc(0.375rem + 1px);
		padding-bottom: calc(0.375rem + 1px);
	}
</style>
