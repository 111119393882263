import { parseISO, formatISO } from 'date-fns'

/** @type {import('houdini').ConfigFile} */
const config = {
	watchSchema: {
		// This is just used for dev, and we never change the /graphql suffix, so this should be fine.
		url(env) {
			return `${env.API_URL}/graphql`
		},
	},
	include: [ 'client/**/*.{svelte,graphql,gql,ts,js}' ],
	plugins: {
		'houdini-svelte': {
			client: './client/services/houdini.ts',
		},
	},
	scalars: {
		PositiveInt: {
			type: 'number',
		},
		EmailAddress: {
			type: 'string',
		},
		UUID: {
			type: 'string',
		},
		NonEmptyString: {
			type: 'string',
		},
		NonNegativeInt: {
			type: 'number',
		},
		Void: {
			type: 'void',
		},
		UnsignedInt: {
			type: 'number',
		},
		SafeInt: {
			type: 'number',
		},
		PhoneNumber: {
			type: 'string',
		},
		UInt: {
			type: 'number',
		},
		Decimal6: {
			type: "string",
			// remove trailing zeroes on "integer" decimals, e.g. "1.000000" -> "1"
			unmarshal: value => value ? value.replace(/\.0+$/, "") : null,
		},
		Decimal2: {
			type: "string",
			unmarshal: value => value ? value.replace(/\.0+$/, "") : null,
		},
		DateTime: {
			type: "Date",
			unmarshal: isoDateString => isoDateString ? parseISO(isoDateString) : null,
			marshal: date => date ? formatISO(date, { representation: 'complete' }) : null,
		},
		Date: {
			type: "Date",
			unmarshal: isoDateString => isoDateString ? parseISO(isoDateString) : null,
			marshal: date => date ? formatISO(date, { representation: 'date' }) : null,
		},
		Upload: {
			type: "File",
		},
	},
	types: {
		// InventoryOptionValue.id can be -1 if a specific Inventory/Option doesn't have a value, so make the key used for caching more broad
		InventoryOptionValue: {
			keys: [ 'id', 'inventoryId', 'optionId' ],
		},
		// Virtual locations have id -1, so include name in the key
		Location: {
			keys: [ 'id', 'name' ],
		},
		// ReportParameterOption has does not have a unique ID, so disable optimization based on that
		ReportParameterOption: {
			keys: [],
		},
	},
	// Caching can have a lot of side effects such as not updating the UI after reloading a state after a save, so I figure it's best to have it be opt-in.
	defaultCachePolicy: 'NetworkOnly',
	defaultFragmentMasking: 'disable',
}

export default config
