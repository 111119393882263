export default {
    "name": "UpdateExistingReportSubscription",
    "kind": "HoudiniMutation",
    "hash": "3e05b61cab9cfbf5504ce5ac93149e3be4cf831dfe48bb817580f6cad441d2d6",

    "raw": `mutation UpdateExistingReportSubscription($inputs: UpdateReportSubscription!) {
  updateReportSubscription(input: $inputs) {
    id
    createdByUserId
    createdByUser {
      id
      firstName
      lastName
    }
    sourceType
    destinationType
    destination
    title
    emailBody
    emailFrom
    attachmentName
    reportId
    nextInstance
    schedulePeriodType
    schedulePeriod
    active
    dateCreated
    comments
    sourceQuery
    parameterDateRange
    report {
      id
      name
      type
      category
      description
    }
    parameters {
      reportParameterId
      value
      reportParameter {
        required
        type
        visible
        label
        name
        editability
        options {
          id
          label
          value
        }
        id
      }
      id
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateReportSubscription": {
                "type": "ReportSubscription",
                "keyRaw": "updateReportSubscription(input: $inputs)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "createdByUserId": {
                            "type": "Int",
                            "keyRaw": "createdByUserId",
                            "visible": true
                        },

                        "createdByUser": {
                            "type": "UserAccount",
                            "keyRaw": "createdByUser",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "sourceType": {
                            "type": "SourceType",
                            "keyRaw": "sourceType",
                            "visible": true
                        },

                        "destinationType": {
                            "type": "DestinationType",
                            "keyRaw": "destinationType",
                            "visible": true
                        },

                        "destination": {
                            "type": "String",
                            "keyRaw": "destination",
                            "nullable": true,
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "visible": true
                        },

                        "emailBody": {
                            "type": "String",
                            "keyRaw": "emailBody",
                            "nullable": true,
                            "visible": true
                        },

                        "emailFrom": {
                            "type": "String",
                            "keyRaw": "emailFrom",
                            "nullable": true,
                            "visible": true
                        },

                        "attachmentName": {
                            "type": "String",
                            "keyRaw": "attachmentName",
                            "nullable": true,
                            "visible": true
                        },

                        "reportId": {
                            "type": "Int",
                            "keyRaw": "reportId",
                            "nullable": true,
                            "visible": true
                        },

                        "nextInstance": {
                            "type": "Date",
                            "keyRaw": "nextInstance",
                            "visible": true
                        },

                        "schedulePeriodType": {
                            "type": "SchedulePeriodType",
                            "keyRaw": "schedulePeriodType",
                            "visible": true
                        },

                        "schedulePeriod": {
                            "type": "Int",
                            "keyRaw": "schedulePeriod",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "dateCreated": {
                            "type": "Date",
                            "keyRaw": "dateCreated",
                            "visible": true
                        },

                        "comments": {
                            "type": "String",
                            "keyRaw": "comments",
                            "nullable": true,
                            "visible": true
                        },

                        "sourceQuery": {
                            "type": "String",
                            "keyRaw": "sourceQuery",
                            "nullable": true,
                            "visible": true
                        },

                        "parameterDateRange": {
                            "type": "DateRange",
                            "keyRaw": "parameterDateRange",
                            "nullable": true,
                            "visible": true
                        },

                        "report": {
                            "type": "Report",
                            "keyRaw": "report",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "parameters": {
                            "type": "ReportSubscriptionParameter",
                            "keyRaw": "parameters",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "reportParameterId": {
                                        "type": "Int",
                                        "keyRaw": "reportParameterId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "reportParameter": {
                                        "type": "ReportParameter",
                                        "keyRaw": "reportParameter",

                                        "selection": {
                                            "fields": {
                                                "required": {
                                                    "type": "Boolean",
                                                    "keyRaw": "required",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "ParameterType",
                                                    "keyRaw": "type",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "visible": {
                                                    "type": "Boolean",
                                                    "keyRaw": "visible",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "editability": {
                                                    "type": "Editability",
                                                    "keyRaw": "editability",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "options": {
                                                    "type": "ReportParameterOption",
                                                    "keyRaw": "options",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "label": {
                                                                "type": "String",
                                                                "keyRaw": "label",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "value": {
                                                                "type": "String",
                                                                "keyRaw": "value",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inputs": "UpdateReportSubscription"
        },

        "types": {
            "ReportSubscriptionParameterInput": {
                "rawValue": "String",
                "reportParameterId": "Int",
                "sqlValue": "String"
            },

            "UpdateReportSubscription": {
                "active": "Boolean",
                "attachmentName": "String",
                "comments": "String",
                "destination": "String",
                "destinationType": "ReportSubscriptionDestinationType",
                "emailBody": "String",
                "emailFrom": "String",
                "nextInstance": "Date",
                "reportDateRange": "DateRange",
                "reportId": "Int",
                "reportSubscriptionId": "Int",
                "reportSubscriptionParameters": "ReportSubscriptionParameterInput",
                "schedulePeriod": "Int",
                "schedulePeriodType": "SchedulePeriodType",
                "sourceQuery": "String",
                "sourceType": "SourceType",
                "title": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=4c907c02aecce2d9e6109e672a2cccfa6d9bead3d29d15f00a47303d2df6f130";