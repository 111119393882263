export default {
    "name": "ReportQueueHistorySettings",
    "kind": "HoudiniQuery",
    "hash": "c2e23d5c1061540839d286573cd8fe7bc48faf53d8bf8db029d9008ae0d37d8b",

    "raw": `query ReportQueueHistorySettings {
  settingValues {
    reportViewerHistory {
      pageSize
      showSentJobs
      showCancelledJobs
      showReadyJobs
      showFailedJobs
      showDelayedJobsOnly
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "settingValues": {
                "type": "SettingValues",
                "keyRaw": "settingValues",

                "selection": {
                    "fields": {
                        "reportViewerHistory": {
                            "type": "ReportViewerHistorySettingValues",
                            "keyRaw": "reportViewerHistory",

                            "selection": {
                                "fields": {
                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "visible": true
                                    },

                                    "showSentJobs": {
                                        "type": "Boolean",
                                        "keyRaw": "showSentJobs",
                                        "visible": true
                                    },

                                    "showCancelledJobs": {
                                        "type": "Boolean",
                                        "keyRaw": "showCancelledJobs",
                                        "visible": true
                                    },

                                    "showReadyJobs": {
                                        "type": "Boolean",
                                        "keyRaw": "showReadyJobs",
                                        "visible": true
                                    },

                                    "showFailedJobs": {
                                        "type": "Boolean",
                                        "keyRaw": "showFailedJobs",
                                        "visible": true
                                    },

                                    "showDelayedJobsOnly": {
                                        "type": "Boolean",
                                        "keyRaw": "showDelayedJobsOnly",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=54a664b8f23aa5956fc3e9894fd790aad52b6cc97d93715311be78e271f07013";