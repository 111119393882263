import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetPrinters'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetPrintersStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetPrintersStore",
			variables: false,
		})
	}
}

export async function load_GetPrinters(params) {
	await initClient()

	const store = new GetPrintersStore()

	await store.fetch(params)

	return {
		GetPrinters: store,
	}
}
