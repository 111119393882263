export default {
    "name": "ReportSubscriptions",
    "kind": "HoudiniQuery",
    "hash": "5f00bcd341bf38bb5d2e6ed1b8b5d0dca9fc4609e5879f391f4028c578cf96bb",

    "raw": `query ReportSubscriptions($reportSubscriptionFilter: ReportSubscriptionFilter, $pagination: PaginationOptions) {
  reportSubscriptions(filter: $reportSubscriptionFilter, pagination: $pagination) {
    items {
      id
      createdByUserId
      createdByUser {
        firstName
        lastName
        id
      }
      sourceType
      destinationType
      destination
      title
      emailBody
      emailFrom
      attachmentName
      reportId
      nextInstance
      schedulePeriodType
      schedulePeriod
      active
      dateCreated
      comments
      sourceQuery
      parameterDateRange
      report {
        id
        name
        type
        category
        description
      }
    }
    pageInfo {
      pageNumber
      pageSize
      totalPages
    }
    totalItems
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reportSubscriptions": {
                "type": "ReportSubscriptionList",
                "keyRaw": "reportSubscriptions(filter: $reportSubscriptionFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "ReportSubscription",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "createdByUserId": {
                                        "type": "Int",
                                        "keyRaw": "createdByUserId",
                                        "visible": true
                                    },

                                    "createdByUser": {
                                        "type": "UserAccount",
                                        "keyRaw": "createdByUser",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "firstName": {
                                                    "type": "String",
                                                    "keyRaw": "firstName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "lastName": {
                                                    "type": "String",
                                                    "keyRaw": "lastName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sourceType": {
                                        "type": "SourceType",
                                        "keyRaw": "sourceType",
                                        "visible": true
                                    },

                                    "destinationType": {
                                        "type": "DestinationType",
                                        "keyRaw": "destinationType",
                                        "visible": true
                                    },

                                    "destination": {
                                        "type": "String",
                                        "keyRaw": "destination",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "title": {
                                        "type": "String",
                                        "keyRaw": "title",
                                        "visible": true
                                    },

                                    "emailBody": {
                                        "type": "String",
                                        "keyRaw": "emailBody",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "emailFrom": {
                                        "type": "String",
                                        "keyRaw": "emailFrom",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "attachmentName": {
                                        "type": "String",
                                        "keyRaw": "attachmentName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "reportId": {
                                        "type": "Int",
                                        "keyRaw": "reportId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "nextInstance": {
                                        "type": "Date",
                                        "keyRaw": "nextInstance",
                                        "visible": true
                                    },

                                    "schedulePeriodType": {
                                        "type": "SchedulePeriodType",
                                        "keyRaw": "schedulePeriodType",
                                        "visible": true
                                    },

                                    "schedulePeriod": {
                                        "type": "Int",
                                        "keyRaw": "schedulePeriod",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "dateCreated": {
                                        "type": "Date",
                                        "keyRaw": "dateCreated",
                                        "visible": true
                                    },

                                    "comments": {
                                        "type": "String",
                                        "keyRaw": "comments",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sourceQuery": {
                                        "type": "String",
                                        "keyRaw": "sourceQuery",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "parameterDateRange": {
                                        "type": "DateRange",
                                        "keyRaw": "parameterDateRange",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "report": {
                                        "type": "Report",
                                        "keyRaw": "report",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "String",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "category": {
                                                    "type": "String",
                                                    "keyRaw": "category",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "pageInfo": {
                            "type": "PageInfo",
                            "keyRaw": "pageInfo",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "totalItems": {
                            "type": "Int",
                            "keyRaw": "totalItems",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "reportSubscriptionFilter": "ReportSubscriptionFilter",
            "pagination": "PaginationOptions"
        },

        "types": {
            "ReportSubscriptionFilter": {
                "createdByUserIds": "Int"
            },

            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=363a8af46740b2f32dda1eeefc8f84942fd66674eb1b6363ab9710ffe316c0ff";