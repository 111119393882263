<svelte:options accessors={true} />

<script lang="ts">
	import Input from '@isoftdata/svelte-input'
	import Textarea from '@isoftdata/svelte-textarea'
	import Select from '@isoftdata/svelte-select'
	import Site from '@isoftdata/svelte-site-details'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import type { Store, State } from 'types/store'
	import type { SvelteAsr } from 'types/common'
	import { updateStoreMutation, autofillByZipQuery } from 'common/graphql-queries'
	import type { Mediator } from 'types/mediator'
	import { getContext } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	//#region global props
	export let asr: SvelteAsr
	//#endregion
	//#region local props
	export let storesChanged: boolean = false
	$: storesChanged = storesToUpdate.length > 0
	export let selectedStoreUuid: string
	export let stores: Store[] = []
	export let states: State[] = []
	export let canConfigureStores: boolean = false
	export let userStores: number[] = []
	//#endregion
	//#region internal props
	const taxServices = [
		//Const for now until fetched from server
		{ value: 'NONE', label: 'None' },
		{ value: 'MOCK', label: 'Mock' },
		{ value: 'TAXJAR', label: 'TaxJar' },
		{ value: 'TAXJAR_SANDBOX', label: 'TaxJar Sandbox' },
		{ value: 'VERTEX', label: 'Vertex' },
	]
	const addressServices = [
		//Const for now until fetched from server
		{ value: 'NONE', label: 'None' },
		{ value: 'MOCK', label: 'Mock' },
		{ value: 'TAXJAR', label: 'TaxJar' },
		{ value: 'TAXJAR_SANDBOX', label: 'TaxJar Sandbox' },
		{ value: 'VERTEX', label: 'Vertex' },
	]
	//#endregion
	//#region internal computed
	$: selectedStoreIndex = stores.findIndex(store => store.uuid === selectedStoreUuid)
	export let storesToUpdate: Store[] = []
	$: storesToUpdate = stores.filter(store => store.dirty === true)
	$: canEditSelectedStore = getStorePermissions(stores[selectedStoreIndex]?.id)
	//#endregion
	//#region internal methods
	function getStorePermissions(storeId: number | null) {
		if (!storeId) {
			return false
		}
		return userStores.includes(storeId)
	}

	function markStoreDirty() {
		stores[selectedStoreIndex].dirty = true
		stores = stores
	}

	function updateRemittanceAddress() {
		if (stores[selectedStoreIndex].remittanceIsMainAddress) {
			stores[selectedStoreIndex].remittanceAddress = stores[selectedStoreIndex].street
			stores[selectedStoreIndex].remittanceCity = stores[selectedStoreIndex].city
			stores[selectedStoreIndex].remittanceState = stores[selectedStoreIndex].state
			stores[selectedStoreIndex].remittanceZip = stores[selectedStoreIndex].zip
			stores[selectedStoreIndex].remittanceCountry = stores[selectedStoreIndex].country
			stores[selectedStoreIndex].remittancePhone = stores[selectedStoreIndex].phone
			stores[selectedStoreIndex].dirty = true
			stores = stores
		}
	}

	function toggleRemittanceAddress() {
		stores[selectedStoreIndex].remittanceIsMainAddress = !stores[selectedStoreIndex].remittanceIsMainAddress
		updateRemittanceAddress()
	}

	async function getLocationDataFromZip() {
		const zip = stores[selectedStoreIndex].zip
		if (!zip) {
			return
		}
		const { autofillByZip } = (await mediator.publish('graphqlFetch', autofillByZipQuery, { zipcode: zip })) as {
			autofillByZip: { city: string; stateAbbreviation: string; country: string }
		}
		if (!autofillByZip) {
			return
		}
		stores[selectedStoreIndex].city = autofillByZip.city
		stores[selectedStoreIndex].state = states.find(state => state.abbreviation === autofillByZip.stateAbbreviation)?.abbreviation ?? ''
		stores[selectedStoreIndex].country = autofillByZip.country
		stores[selectedStoreIndex].dirty = true
		stores = stores
	}

	async function saveStore() {
		mediator.publish('showMessage', {
			heading: 'Saving...',
			message: '',
			type: 'info',
			time: false,
		})
		try {
			storesToUpdate.length > 0
				? await Promise.all(
						storesToUpdate.map(store =>
							mediator.publish('graphqlFetch', updateStoreMutation, {
								input: {
									id: store.id,
									addressService: {
										service: store.addressService.service,
										apiKey: store.addressService.apiKey,
									},
									taxService: {
										service: store.taxService.service,
										apiKey: store.taxService.apiKey,
									},
									code: store.code,
									name: store.name,
									address: store.street,
									city: store.city,
									state: store.state,
									zip: store.zip,
									country: store.country,
									phone: store.phone,
									fax: store.fax,
									remittanceAddress: store.remittanceAddress,
									remittanceCity: store.remittanceCity,
									remittanceState: store.remittanceState,
									remittanceZip: store.remittanceZip,
									remittanceCountry: store.remittanceCountry,
									remittancePhone: store.remittancePhone,
									webAddress: store.webAddress,
									invoiceMemo: store.invoiceMemo,
									private: booleanToString(store.private).toUpperCase(),
								},
							}),
						),
					)
				: null
			// Set this to false to not trip `canLeaveState`
			storesChanged = false
			mediator.publish('showMessage', {
				heading: 'Saved!',
				message: 'Stores saved successfully.',
				type: 'success',
				time: 10000,
			})
			// Reload the page after a successful save, but not after an error
			asr.go(null, { lastResetTime: null, lastSavedTime: Date.now() }, { inherit: true })
		} catch (err: any) {
			let theError: Error
			// Error might be in an array
			if (Array.isArray(err) && 'message' in err[0]) {
				theError = err[0] as Error
			} else if ('message' in err) {
				theError = err as Error
			} else {
				theError = new Error('Unknown Error')
			}
			console.error(theError)
			mediator.publish('showMessage', {
				heading: 'Error Saving Stores',
				message: theError.message,
				type: 'danger',
				time: false,
			})
		}
	}
	//#endregion
</script>

<div class="form-row">
	<div class="col-lg-3 col-md-3 col-sm-6 pr-1">
		<Select
			label="Select Store"
			showEmptyOption={false}
			options={stores}
			bind:value={selectedStoreUuid}
			let:option={store}
		>
			<option value={store?.uuid}>{store?.code} - {store?.name}</option>
		</Select>
	</div>
	<div class="col-12 col-sm text-right">
		<SaveResetButton
			disabled={!storesChanged || !canEditSelectedStore}
			resetHref={asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true })}
			on:saveClick={saveStore}
		/>
	</div>
</div>

<div class="form-row">
	<div class="col-12 col-md-4 mb-2">
		<Site
			bind:sites={stores}
			{states}
			siteAlias="Store"
			canEditSelectedSite={canEditSelectedStore && canConfigureStores}
			showTimezone={false}
			bind:selectedSiteUuid={selectedStoreUuid}
			updateLocationFieldsFromZip={getLocationDataFromZip}
			hideCreateDeleteButtons={true}
			on:fieldChange={updateRemittanceAddress}
		/>
	</div>
	<div class="col-12 col-md-4 mb-2">
		<div class="card h-100">
			<div class="card-header">
				<h5>Remittance Address</h5>
			</div>
			<div class="card-body">
				<div class="form-row">
					<div class="col-12">
						<Checkbox
							disabled={!canEditSelectedStore || !canConfigureStores}
							label="Remittance address is the same as physical address"
							checked={stores[selectedStoreIndex].remittanceIsMainAddress}
							on:change={toggleRemittanceAddress}
						/>
					</div>
					<div class="col-12">
						<Input
							disabled={stores[selectedStoreIndex].remittanceIsMainAddress || !canEditSelectedStore || !canConfigureStores}
							label="Address"
							placeholder="Street Address"
							bind:value={stores[selectedStoreIndex].remittanceAddress}
							on:change={markStoreDirty}
						/>
					</div>
					<div class="col-md-4 col-6">
						<Input
							disabled={stores[selectedStoreIndex].remittanceIsMainAddress || !canEditSelectedStore || !canConfigureStores}
							label="City"
							placeholder="City"
							bind:value={stores[selectedStoreIndex].remittanceCity}
							on:change={markStoreDirty}
						/>
					</div>
					<div class="col-md-3 col-6">
						<Select
							disabled={stores[selectedStoreIndex].remittanceIsMainAddress || !canEditSelectedStore || !canConfigureStores}
							label="State"
							showEmptyOption={true}
							emptyText="State"
							emptyValue={''}
							options={states}
							bind:value={stores[selectedStoreIndex].remittanceState}
							let:option={state}
							on:change={markStoreDirty}
						>
							<option value={state?.abbreviation}>{state?.name}</option>
						</Select>
					</div>
					<div class="col-md-2 col-6">
						<Input
							disabled={stores[selectedStoreIndex].remittanceIsMainAddress || !canEditSelectedStore || !canConfigureStores}
							placeholder="Zip"
							label="Zip"
							bind:value={stores[selectedStoreIndex].remittanceZip}
							on:change={markStoreDirty}
						/>
					</div>
					<div class="col-md-3 col-6">
						<Input
							disabled={stores[selectedStoreIndex].remittanceIsMainAddress || !canEditSelectedStore || !canConfigureStores}
							placeholder="Country"
							label="Country"
							bind:value={stores[selectedStoreIndex].remittanceCountry}
							on:change={markStoreDirty}
						/>
					</div>
					<div class="col-12">
						<Input
							disabled={stores[selectedStoreIndex].remittanceIsMainAddress || !canEditSelectedStore || !canConfigureStores}
							label="Remittance Phone"
							placeholder="Phone#"
							bind:value={stores[selectedStoreIndex].remittancePhone}
							on:change={markStoreDirty}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-4 mb-2">
		<div class="card h-100">
			<div class="card-header">
				<h5>Misc Details</h5>
			</div>
			<div class="card-body">
				<Input
					disabled={!canEditSelectedStore || !canConfigureStores}
					placeholder="URL"
					label="Web Address"
					bind:value={stores[selectedStoreIndex].webAddress}
					on:change={markStoreDirty}
				/>
				<Textarea
					disabled={!canEditSelectedStore || !canConfigureStores}
					label="Invoice Memo"
					bind:value={stores[selectedStoreIndex].invoiceMemo}
					on:change={markStoreDirty}
				/>
			</div>
		</div>
	</div>
</div>
<div class="form-row">
	<div class="col-12 col-md-6 mb-2">
		<div class="card">
			<div class="card-header">
				<h5>Tax Service</h5>
			</div>
			<div class="card-body">
				<Select
					label="Tax Service"
					showEmptyOption={false}
					disabled={!canConfigureStores || !canEditSelectedStore}
					options={taxServices}
					bind:value={stores[selectedStoreIndex].taxService.service}
					let:option={taxService}
					on:change={markStoreDirty}
				>
					<option value={taxService?.value}>{taxService?.label}</option>
				</Select>
				<Input
					label="Tax Service API Key"
					type="password"
					disabled={!canConfigureStores || !canEditSelectedStore}
					bind:value={stores[selectedStoreIndex].taxService.apiKey}
					on:change={markStoreDirty}
				/>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-6">
		<div class="card">
			<div class="card-header">
				<h5>Address Service</h5>
			</div>
			<div class="card-body">
				<Select
					label="Address Service"
					showEmptyOption={false}
					disabled={!canConfigureStores || !canEditSelectedStore}
					options={addressServices}
					bind:value={stores[selectedStoreIndex].addressService.service}
					let:option={addressService}
					on:change={markStoreDirty}
				>
					<option value={addressService?.value}>{addressService?.label}</option>
				</Select>
				<Input
					label="Address Service API Key"
					type="password"
					disabled={!canConfigureStores || !canEditSelectedStore}
					bind:value={stores[selectedStoreIndex].addressService.apiKey}
					on:change={markStoreDirty}
				/>
			</div>
		</div>
	</div>
</div>
