export default {
    "name": "SetPrintJobStatus",
    "kind": "HoudiniMutation",
    "hash": "8ad93133dc29b9debdd256bc6794c41e605d83ac2ae1a66833447664fe56d6ae",

    "raw": `mutation SetPrintJobStatus($input: [SetPrintJobStatusInput!]!) {
  setPrintJobStatus(input: $input)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setPrintJobStatus": {
                "type": "Boolean",
                "keyRaw": "setPrintJobStatus(input: $input)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "SetPrintJobStatusInput"
        },

        "types": {
            "SetPrintJobStatusInput": {
                "id": "Int",
                "status": "ReportQueueStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=f34406e83cd5aed1740c7438667f9d84cf9cf30cab0672dcdf6581a573c17b39";