export default {
    "name": "GetReportParameters",
    "kind": "HoudiniQuery",
    "hash": "2d75ac13d3bd4b04b0ff58aebd0ef7c451642173c4a2fb6e982b7ba908a3ffe2",

    "raw": `query GetReportParameters($reportId: UInt!) {
  report(id: $reportId) {
    id
    type
    category
    name
    parameters {
      id
      label
      name
      rank
      type
      visible
      editability
      required
      options {
        isDefault
        id
        label
        value
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "report": {
                "type": "Report",
                "keyRaw": "report(id: $reportId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "type": {
                            "type": "String",
                            "keyRaw": "type",
                            "visible": true
                        },

                        "category": {
                            "type": "String",
                            "keyRaw": "category",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "parameters": {
                            "type": "ReportParameter",
                            "keyRaw": "parameters",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "ParameterType",
                                        "keyRaw": "type",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "visible": {
                                        "type": "Boolean",
                                        "keyRaw": "visible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "editability": {
                                        "type": "Editability",
                                        "keyRaw": "editability",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "required": {
                                        "type": "Boolean",
                                        "keyRaw": "required",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "ReportParameterOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "isDefault": {
                                                    "type": "Boolean",
                                                    "keyRaw": "isDefault",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "reportId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=5085bb721d5f57ca2aa056e3a327678ff375529e15e9d27eccda14cc5403c94a";