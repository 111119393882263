export default {
    "name": "GenerateCrystalReportForSubscription",
    "kind": "HoudiniMutation",
    "hash": "dab242801e1bd42f934c906dd5d90f3de7ef02395c6d315bded66d9f8c5b5188",

    "raw": `mutation GenerateCrystalReportForSubscription($reportJob: GenerateCrystalReportInput!) {
  generateCrystalReport(input: $reportJob) {
    data
    mimeType
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "generateCrystalReport": {
                "type": "GenerateCrystalReportResponse",
                "keyRaw": "generateCrystalReport(input: $reportJob)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "String",
                            "keyRaw": "data",
                            "visible": true
                        },

                        "mimeType": {
                            "type": "String",
                            "keyRaw": "mimeType",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "reportJob": "GenerateCrystalReportInput"
        },

        "types": {
            "ReportParameterInput": {
                "key": "String",
                "value": "String"
            },

            "GenerateCrystalReportInput": {
                "id": "Int",
                "name": "String",
                "parameters": "ReportParameterInput",
                "type": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=082652c9bfd0b73fe15064edbeadad07eaac3ee0d9f31ce95f77d38f8635208e";