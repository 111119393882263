export default {
    "name": "GetFavoriteReports",
    "kind": "HoudiniQuery",
    "hash": "bcfe3c22c401cf3d6c0b97aba9ac1aca10163c014edb759dc305bcda736e7876",

    "raw": `query GetFavoriteReports {
  session {
    userAccount {
      favoriteReports: settings {
        reportViewer {
          favoriteReportNames
        }
      }
      id
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "favoriteReports": {
                                        "type": "UserSettings",
                                        "keyRaw": "favoriteReports",

                                        "selection": {
                                            "fields": {
                                                "reportViewer": {
                                                    "type": "ReportViewerSettingValues",
                                                    "keyRaw": "reportViewer",

                                                    "selection": {
                                                        "fields": {
                                                            "favoriteReportNames": {
                                                                "type": "String",
                                                                "keyRaw": "favoriteReportNames",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=abe3b59a837c3d8f3d91f1839442368c4e19a083560c66aa7284d7f44326bb1d";