export default {
    "name": "GetPrinters",
    "kind": "HoudiniQuery",
    "hash": "148b3c87035f2766520dcf7b5c80b58ea3e10552c2e615b7cb76409fda726fa1",

    "raw": `query GetPrinters($filter: ReportQueuePrinterFilter) {
  printers: reportQueuePrinters(
    filter: $filter
    pagination: {pageSize: 10000, pageNumber: 1}
  ) {
    items {
      dateLastSeen
      displayName
      hidden
      id
      name
      online
      storeId
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "printers": {
                "type": "ReportQueuePrinterList",
                "keyRaw": "printers(filter: $filter, pagination: {pageSize: 10000, pageNumber: 1})",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "ReportQueuePrinter",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "dateLastSeen": {
                                        "type": "Date",
                                        "keyRaw": "dateLastSeen",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "displayName": {
                                        "type": "String",
                                        "keyRaw": "displayName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "hidden": {
                                        "type": "Boolean",
                                        "keyRaw": "hidden",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "online": {
                                        "type": "Boolean",
                                        "keyRaw": "online",
                                        "visible": true
                                    },

                                    "storeId": {
                                        "type": "Int",
                                        "keyRaw": "storeId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "ReportQueuePrinterFilter"
        },

        "types": {
            "ReportQueuePrinterFilter": {
                "hidden": "Boolean",
                "online": "Boolean",
                "reportTypes": "String",
                "storeIds": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8b3b9d8d5553e2c4596d1b2b0d352608f9bb3ca76d02b50c4b9cb3c92b4eefd5";