import { localWritable } from '@macfja/svelte-persistent-store'
import { get } from 'svelte/store'

type UserValueMap<T> = {
	[userAccountId: number]: T
}

/** Create a store that stores a value on a per-user basis by abstracting away the fact that it's a map over user:value, and only getting/setting the value for the current user. */
export default function userLocalWritable<T>(userAccountId: number, name: string, init?: T) {
	const theStore = localWritable<UserValueMap<T>>(name, {})

	theStore.update(userValueMap => {
		// Make sure this localstorage item is an object before we use object operators on it, or it'll throw
		if (typeof userValueMap !== 'object') {
			userValueMap = {}
		}
		// Initialize the user's value if it doesn't exist yet
		if (init !== undefined && !(userAccountId in userValueMap)) {
			userValueMap[userAccountId] = init
		}
		return userValueMap
	})

	return {
		set(value: T) {
			theStore.update(userValueMap => {
				userValueMap[userAccountId] = value
				return userValueMap
			})
		},
		subscribe: (cb: (value: T) => void) => {
			return theStore.subscribe(userValueMap => {
				cb(userValueMap[userAccountId])
			})
		},
		/** delete the user's value, or, if that's the only value left, delete the whole store from localstorage */
		delete() {
			let deleteStore = false
			theStore.update(userValueMap => {
				delete userValueMap[userAccountId]
				deleteStore = Object.keys(userValueMap).length > 0

				return userValueMap
			})
			if (deleteStore) {
				theStore.delete()
			}
		},
		get() {
			return get(theStore)[userAccountId] ?? null
		},
	}
}

export type UserLocalWritable<T> = ReturnType<typeof userLocalWritable<T>>
