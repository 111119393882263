export default {
    "name": "ReportSubscriptionParameters",
    "kind": "HoudiniQuery",
    "hash": "be81f282996fa9477d1ed40e2a4666fe684bb03a826124574e20871d41126986",

    "raw": `query ReportSubscriptionParameters($reportSubscriptionId: Int!) {
  reportSubscriptionParameters(reportSubscriptionId: $reportSubscriptionId) {
    items {
      reportParameterId
      value
      reportParameter {
        required
        type
        visible
        label
        name
        editability
        options {
          id
          label
          value
          isDefault
        }
        id
      }
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reportSubscriptionParameters": {
                "type": "ReportSubscriptionParameterList",
                "keyRaw": "reportSubscriptionParameters(reportSubscriptionId: $reportSubscriptionId)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "ReportSubscriptionParameter",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "reportParameterId": {
                                        "type": "Int",
                                        "keyRaw": "reportParameterId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "reportParameter": {
                                        "type": "ReportParameter",
                                        "keyRaw": "reportParameter",

                                        "selection": {
                                            "fields": {
                                                "required": {
                                                    "type": "Boolean",
                                                    "keyRaw": "required",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "ParameterType",
                                                    "keyRaw": "type",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "visible": {
                                                    "type": "Boolean",
                                                    "keyRaw": "visible",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "editability": {
                                                    "type": "Editability",
                                                    "keyRaw": "editability",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "options": {
                                                    "type": "ReportParameterOption",
                                                    "keyRaw": "options",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "label": {
                                                                "type": "String",
                                                                "keyRaw": "label",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "value": {
                                                                "type": "String",
                                                                "keyRaw": "value",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "isDefault": {
                                                                "type": "Boolean",
                                                                "keyRaw": "isDefault",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "reportSubscriptionId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c1d0679c8e7ebc0207a67f4d7b0121f1ae5dedab9c69185457cbe6c817dfea46";