import type { AppContext, ResolveParameters } from 'types/common'

import component from './History.svelte'
import { graphql, ReportQueueJobSort, type ReportQueueJobs$result, type ReportQueueJobSort$options, type ReportQueueStatus$options } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import makeSettingValueStore from 'stores/setting-value'

type StateParameters = {
	showSentJobs: boolean
	showCancelledJobs: boolean
	pageNumber: number
	sortDirection: 'ASC' | 'DESC'
	sortColumnProperty: string
}

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.history',
		route: 'history',
		defaultParameters: {
			pageNumber: '1',
			sortDirection: 'DESC',
			sortColumnProperty: 'dateCreated',
		},
		querystringParameters: ['showSentJobs', 'showCancelledJobs', 'showReadyJobs', 'showFailedJobs', 'showDelayedJobsOnly', 'pageNumber', 'sortDirection', 'sortColumnProperty', 'lastRefreshTime'],
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, parameters: ResolveParameters<StateParameters>) {
			const { data: settingsData } = await settingsQuery.fetch()
			if (!settingsData) {
				throw showErrorAndRedirect(mediator, 'Failed to load Print Job History Settings', 'No data returned from server', { name: 'app.report-viewer' })
			}

			const showSentJobs = parameters.showSentJobs ?? settingsData.settingValues.reportViewerHistory.showSentJobs
			const showCancelledJobs = parameters.showCancelledJobs ?? settingsData.settingValues.reportViewerHistory.showCancelledJobs
			const showReadyJobs = parameters.showReadyJobs ?? settingsData.settingValues.reportViewerHistory.showReadyJobs
			const showFailedJobs = parameters.showFailedJobs ?? settingsData.settingValues.reportViewerHistory.showFailedJobs
			const showDelayedJobsOnly = parameters.showDelayedJobsOnly ?? settingsData.settingValues.reportViewerHistory.showDelayedJobsOnly
			const pageNumber = parseInt(parameters.pageNumber, 10) || 1
			const pageSize = settingsData.settingValues.reportViewerHistory.pageSize ?? 100
			const sortDirection = parameters.sortDirection ?? 'ASC'
			const sortColumnProperty = parameters.sortColumnProperty ?? 'dateCreated'

			const statusFilter: Array<ReportQueueStatus$options> = []

			if (showSentJobs) {
				statusFilter.push('SENT')
			}

			if (showCancelledJobs) {
				statusFilter.push('CANCELLED')
			}

			if (showReadyJobs) {
				statusFilter.push('READY')
			}

			if (showFailedJobs) {
				statusFilter.push('FAILED')
			}

			let orderBy: Array<ReportQueueJobSort$options> = []

			const orderByString = `${sortColumnProperty}_${sortDirection}`

			if (orderByString in ReportQueueJobSort) {
				orderBy = [orderByString as ReportQueueJobSort$options]
			}

			let history: ReportQueueJobs$result['reportQueueJobs']['items'] = []
			let totalItemsCount = 0

			if (statusFilter.length > 0) {
				const { data } = await reportQueueJobsQuery.fetch({
					variables: {
						pagination: {
							pageNumber,
							pageSize,
						},
						filter: {
							status: statusFilter,
							delayed: showDelayedJobsOnly ? true : undefined,
						},
						orderBy,
					},
				})
				if (!data?.reportQueueJobs) {
					throw showErrorAndRedirect(mediator, 'Failed to load Print Job History', 'No data returned from server', { name: 'app.report-viewer' })
				}
				history = data.reportQueueJobs.items
				totalItemsCount = data.reportQueueJobs.totalItems
			}

			return {
				history,
				currentPageNumber: pageNumber,
				sortDirection,
				sortColumnProperty,
				perPageCount: pageSize,
				totalItemsCount,
				showSentJobs: makeSettingValueStore({
					category: 'reportViewerHistory',
					name: 'showSentJobs',
					initialValue: settingsData.settingValues.reportViewerHistory.showSentJobs,
				}),
				showCancelledJobs: makeSettingValueStore({
					category: 'reportViewerHistory',
					name: 'showCancelledJobs',
					initialValue: settingsData.settingValues.reportViewerHistory.showCancelledJobs,
				}),
				showReadyJobs: makeSettingValueStore({
					category: 'reportViewerHistory',
					name: 'showReadyJobs',
					initialValue: settingsData.settingValues.reportViewerHistory.showReadyJobs,
				}),
				showFailedJobs: makeSettingValueStore({
					category: 'reportViewerHistory',
					name: 'showFailedJobs',
					initialValue: settingsData.settingValues.reportViewerHistory.showFailedJobs,
				}),
				showDelayedJobsOnly: makeSettingValueStore({
					category: 'reportViewerHistory',
					name: 'showDelayedJobsOnly',
					initialValue: settingsData.settingValues.reportViewerHistory.showDelayedJobsOnly,
				}),
			}
		},
	})
}

const reportQueueJobsQuery = graphql(`
	query ReportQueueJobs($pagination: PaginationOptions, $filter: ReportQueueFilter, $orderBy: [ReportQueueJobSort!]!) {
		reportQueueJobs(pagination: $pagination, filter: $filter, orderBy: $orderBy) {
			items {
				id
				reportId
				type
				name
				quantity
				dateCreated
				destinationType
				destination
				status
				executionTime
				notes
				error

				parameters {
					key: parameterName
					value
				}
				createdByUser {
					name
				}
			}
			pageInfo {
				pageNumber
			}
			totalItems
		}
	}
`)

const settingsQuery = graphql(`
	query ReportQueueHistorySettings {
		settingValues {
			reportViewerHistory {
				pageSize
				showSentJobs
				showCancelledJobs
				showReadyJobs
				showFailedJobs
				showDelayedJobsOnly
			}
		}
	}
`)
