<script lang="ts">
	import type { PartForClient as Part, SettingValues } from 'utility/load-part'
	import { graphql, type AppraisalQuery$input, type AppraisalQuery$result, type PartScreenStageOne$result } from '$houdini'
	import type { Mediator } from 'types/mediator'
	import type { Merge } from 'type-fest'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import Select from '@isoftdata/svelte-select'
	import TextArea from '@isoftdata/svelte-textarea'
	import Button from '@isoftdata/svelte-button'
	import Input from '@isoftdata/svelte-input'
	import CurrencyInput from '@isoftdata/svelte-currency-input'

	import { hasPermission } from 'utility/permission'
	import { createEventDispatcher, getContext, onMount } from 'svelte'
	import { format as currencyFormat } from '@isoftdata/utility-currency'
	import { reduceToTruthyValues } from '@isoftdata/utility-object'
	import Popover from 'components/Popover.svelte'
	import Label from '@isoftdata/svelte-label'

	export let part: Merge<
		Pick<
			Part,
			| 'averageCoreCost'
			| 'averageCost'
			| 'category'
			| 'condition'
			| 'coreCost'
			| 'coreRequired'
			| 'coreRequiredToVendor'
			| 'cost'
			| 'deplete'
			| 'description'
			| 'defaultVendor'
			| 'distributorCorePrice'
			| 'distributorPrice'
			| 'inventoryTypeId'
			| 'jobberCorePrice'
			| 'jobberPrice'
			| 'listPrice'
			| 'model'
			| 'partNumber'
			| 'public'
			| 'quantity'
			| 'replenishable'
			| 'retailCorePrice'
			| 'retailPrice'
			| 'saleClass'
			| 'storeId'
			| 'vehicleMake'
			| 'vehicleModel'
			| 'vehicleYear'
			| 'wholesaleCorePrice'
			| 'wholesalePrice'
		>,
		{
			defaultVendor: Pick<NonNullable<Part['defaultVendor']>, 'id'> | null
			category: Pick<NonNullable<Part['category']>, 'id' | 'name'> | null
		}
	>
	export let origPart: Pick<Part, 'quantity'>
	export let vehicle: Pick<NonNullable<Part['vehicle']>, 'year' | 'manufacturer' | 'model'> | null
	export let settingValues: {
		inventory: Pick<
			SettingValues['inventory'],
			| 'categoryRequired'
			| 'conditionRequired'
			| 'costVisible'
			| 'costEditable'
			| 'costRequired'
			| 'coreCostVisible'
			| 'coreCostEditable'
			| 'coreCostRequired'
			| 'distributorPriceVisible'
			| 'distributorPriceEditable'
			| 'distributorCorePriceVisible'
			| 'distributorCorePriceEditable'
			| 'distributorPriceRequired'
			| 'jobberPriceVisible'
			| 'jobberPriceEditable'
			| 'jobberPriceRequired'
			| 'jobberCorePriceVisible'
			| 'jobberCorePriceEditable'
			| 'jobberCorePriceRequired'
			| 'wholesalePriceVisible'
			| 'wholesalePriceEditable'
			| 'wholesalePriceRequired'
			| 'wholesaleCorePriceVisible'
			| 'wholesaleCorePriceEditable'
			| 'wholesaleCorePriceRequired'
			| 'retailPriceVisible'
			| 'retailPriceEditable'
			| 'retailPriceRequired'
			| 'retailCorePriceVisible'
			| 'retailCorePriceEditable'
			| 'retailCorePriceRequired'
			| 'listPriceVisible'
			| 'listPriceEditable'
			| 'listPriceRequired'
		>
	}

	export let inventoryTypeCategories: Array<{
		id: number | null
		name: string
	}>
	export let saleClasses: PartScreenStageOne$result['saleClasses']
	export let inventoryConditions: Array<string>
	export let manufacturerName: string | null
	export let modelName: string | null
	export let disableGlobalFields: boolean
	export let disableSkuFields: boolean
	export let entityContext: 'vehicle' | 'part' = 'part'

	let showAllPriceFields = false
	let appraisal: AppraisalQuery$result['appraisal'][number] = null
	let appraisalDetails = ''

	const noneSaleClass = saleClasses.find(saleClass => saleClass.code === 'NONE') ?? null
	const dispatch = createEventDispatcher<{
		categoryChange: void
	}>()
	const mediator = getContext<Mediator>('mediator')

	function shouldShowPriceField(price: number | string, replenishable: boolean, showAllPriceFields: boolean) {
		return replenishable || showAllPriceFields || Number(price) !== 0
	}

	export async function updateDefaultPricing() {
		if (part.model && part.category) {
			try {
				const { data } = await defaultPricingQuery.fetch({
					variables: {
						input: {
							category: part.category.name,
							coreCost: parseInt(part.coreCost, 10),
							cost: parseInt(part.cost, 10),
							modelId: part.model.id,
							partNumber: part.partNumber,
							storeId: part.storeId,
							vendorId: part.defaultVendor?.id ?? null,
						},
					},
				})
				if (data?.defaultPricing) {
					const pricing = data.defaultPricing

					part.distributorPrice = pricing.distributor
					part.distributorCorePrice = pricing.distributorCore
					part.jobberPrice = pricing.jobber
					part.jobberCorePrice = pricing.jobberCore
					part.retailPrice = pricing.retail
					part.retailCorePrice = pricing.retailCore
					part.listPrice = pricing.list
					part.wholesalePrice = pricing.wholesale
					part.wholesaleCorePrice = pricing.wholesaleCore
				}
			} catch (err: any) {
				mediator.publish('showMessage', {
					heading: 'Error Updating Default Pricing',
					message: err.message,
					color: 'danger',
					time: false,
				})
			}
		}
	}

	export async function appraise(manual = false) {
		const obj = {
			category: part.category?.name ?? '',
			manufacturer: manufacturerName,
			model: modelName,
			inventoryTypeId: part.inventoryTypeId,
			originCode: 'HTP_LIVE',
			year: vehicle?.year || part.vehicleYear,
			vehicleMake: vehicle?.manufacturer?.name || part.vehicleMake,
			vehicleModel: vehicle?.model?.name || part.vehicleModel,
		}

		const appraisalInput = reduceToTruthyValues({
			object: obj,
		}) as Partial<typeof obj>

		// Type narrow the object so we know we have all the required fields
		function isAppraisalInput(input: any): input is AppraisalQuery$input['inputs'][number] {
			return !!input.inventoryTypeId && !!input.originCode
		}

		// We get a graqhql query error if we don't have all the required fields
		if (manual && !isAppraisalInput(appraisalInput)) {
			mediator.publish('showMessage', {
				heading: 'Missing Required Fields',
				message: 'Please select a part type before running an appraisal.',
				color: 'warning',
				time: false,
			})
			return
		} else if (!isAppraisalInput(appraisalInput)) {
			// fail silently if it wasn't triggered by a user action
			appraisalDetails = 'Missing required fields to appraise.'
			return
		}

		const { data } = await appraisalQuery.fetch({
			variables: {
				inputs: [appraisalInput],
			},
		})
		if (!data) {
			return
		}
		const partAppraisal: AppraisalQuery$result['appraisal'][number] = data?.appraisal[0]
		appraisal = partAppraisal
		appraisalDetails = [
			//
			`Min: ${currencyFormat(partAppraisal?.min ?? 0)}`,
			`Max: ${currencyFormat(partAppraisal?.max ?? 0)}`,
			`Total Items: ${partAppraisal?.itemsAffected || 0}`,
		].join('\n')
	}

	onMount(appraise)

	const defaultPricingQuery = graphql(`
		query LIB_defaultPricing($input: DefaultPricingInput!) {
			defaultPricing(input: $input) {
				distributor
				distributorCore
				jobber
				jobberCore
				list
				retail
				retailCore
				wholesale
				wholesaleCore
			}
		}
	`)

	const appraisalQuery = graphql(`
		query AppraisalQuery($inputs: [AppraisalInput!]!) {
			appraisal(inputs: $inputs) {
				value
				relevance
				min
				max
				itemsAffected
				deviance
			}
		}
	`)
</script>

<div class="form-row">
	<div class="col-12 col-xl-6">
		<div class="form-row">
			<div class="col-12 col-sm-6">
				<Autocomplete
					id="categorySelect"
					label="Category"
					title="Inventory category can be used to group items by quality and type in advertisement exports"
					emptyValue={null}
					disabled={disableSkuFields}
					required={!!settingValues.inventory.categoryRequired}
					options={inventoryTypeCategories}
					getLabel={category => category?.name ?? ''}
					bind:value={part.category}
					on:change={() => {
						appraise()
						updateDefaultPricing()
						dispatch('categoryChange')
					}}
				></Autocomplete>
			</div>
			<div class="col-12 col-sm-6">
				<Label
					labelOnly
					label="Condition"
					labelClass="text-underline"
					controlFor="conditionAutocomplete"
					title="User specified level of item's quality and functionality"
					required={!!settingValues.inventory.conditionRequired}
				>
					{#if settingValues.inventory.conditionRequired && !part.condition}
						<small class="text-danger">Required</small>
					{/if}
					<Autocomplete
						id="conditionAutocomplete"
						showLabel={false}
						required={!!settingValues.inventory.conditionRequired}
						placeholder={hasPermission('PARTS_CAN_CREATE_CONDITIONS', part.storeId) ? 'Enter or Select a Condition' : 'Select a Condition'}
						canAddNew={hasPermission('PARTS_CAN_CREATE_CONDITIONS', part.storeId)}
						options={inventoryConditions}
						getLabel={condition => condition ?? ''}
						disabled={disableGlobalFields}
						bind:value={part.condition}
					/>
				</Label>
			</div>
			<div class="col-12 col-md-6">
				<Select
					label="Online Sale Class"
					labelClass="text-underline"
					showEmptyOption={!part.saleClass}
					title="For items available for online sale, the promised turnaround for orders"
					disabled={!part.public || disableGlobalFields}
					bind:value={part.saleClass}
				>
					{#if !part.public}
						<option value={noneSaleClass}>Not for Sale Online</option>
					{:else}
						{#each saleClasses as option}
							<option value={option}>{option.code} - {option.name}</option>
						{/each}
					{/if}
				</Select>
			</div>
			<div class="col-12 col-md-6">
				<TextArea
					label="Description"
					labelClass="text-underline"
					for="description"
					rows="4"
					title="Description of the item. (Shows on document printouts)"
					bind:value={part.description}
					disabled={disableGlobalFields}
				/>
			</div>
		</div>
	</div>
	<div class="col-12 col-xl-6">
		<div class="card">
			<div class="card-header d-flex justify-content-between">
				<h5 class="mb-0">Pricing</h5>
				{#if !part.replenishable && entityContext !== 'vehicle'}
					<Button
						outline
						size="sm"
						iconClass={showAllPriceFields ? 'minus' : 'plus'}
						title="Some price fields are hidden by default on non-replenishable parts if they are set to 0. Click here to show all price fields."
						on:click={() => (showAllPriceFields = !showAllPriceFields)}
						>{#if showAllPriceFields}Less{:else}More{/if}...</Button
					>
				{/if}
			</div>
			<div class="card-body">
				<div class="form-row">
					{#if hasPermission('PARTS_CAN_VIEW_COST', part.storeId)}
						{#if shouldShowPriceField(part.averageCost, part.replenishable, showAllPriceFields) && entityContext !== 'vehicle'}
							<div class="col-6 col-xl-3">
								{#if part.deplete && part.quantity !== 0}
									<CurrencyInput
										readonly
										id="average-cost"
										label="Average Cost"
										name="averageCost"
										title="This represents any historically accounted expenses for this item, averaged across quantity on hand.&#013For items associated with a vehicle, this represents 'add-on costs' that have been earmarked for this item."
										showAppend={part.quantity !== origPart.quantity}
										value={part.averageCost}
									>
										<svelte:fragment slot="append">
											<Popover
												color="warning"
												iconClass="warning"
												style="border-radius: 0 0.2rem 0.2rem 0;"
											>
												<svelte:fragment slot="popover">
													<div class="popover-header text-nowrap">Quantity Changed!</div>
													<div class="popover-body">Quantity has been changed. Save the {entityContext} to see updated average cost.</div>
												</svelte:fragment>
											</Popover>
										</svelte:fragment></CurrencyInput
									>
								{:else}
									<!-- CurrencyInput won't display N/A, so use a normal one-->
									<Input
										readonly
										id="average-cost"
										label="Average Cost"
										name="averageCost"
										title={part.deplete
											? 'Inventory with no quantity on hand cannot have an average cost per item'
											: 'Non-depletable inventory ignores quantity on hand, and therefore cannot have an average cost'}
										value="N/A"
									>
										<span
											slot="prepend"
											class="input-group-text">$</span
										>
									</Input>
								{/if}
							</div>
						{/if}
						{#if shouldShowPriceField(part.averageCoreCost, part.replenishable, showAllPriceFields) && entityContext !== 'vehicle'}
							<div class="col-6 col-xl-3">
								{#if part.deplete && part.quantity !== 0}
									<CurrencyInput
										readonly
										id="average-core-cost"
										label="Average Core Cost"
										name="averageCoreCost"
										title="The average core cost per quantity on hand&#013This can come from purchase expenses (core charges on POs) or build/repair orders on an item with a core charge (WOs containing items with core costs)"
										showAppend={part.quantity !== origPart.quantity}
										value={part.averageCoreCost}
									>
										<svelte:fragment slot="append">
											<Popover
												color="warning"
												iconClass="warning"
												style="border-radius: 0 0.2rem 0.2rem 0;"
											>
												<svelte:fragment slot="popover">
													<div class="popover-header text-nowrap">Quantity Changed!</div>
													<div class="popover-body">Quantity has been changed. Save the part to see updated average core cost.</div>
												</svelte:fragment>
											</Popover>
										</svelte:fragment></CurrencyInput
									>
								{:else}
									<Input
										readonly
										id="average-core-cost"
										label="Average Core Cost"
										name="averageCoreCost"
										title={part.deplete
											? 'Inventory with no quantity on hand cannot have an average core cost per item'
											: 'Non-depletable inventory ignores quantity on hand, and therefore cannot have an average core cost'}
										value="N/A"
									>
										<span
											slot="prepend"
											class="input-group-text">$</span
										>
									</Input>
								{/if}
							</div>
						{/if}
						{#if settingValues.inventory.costVisible && entityContext !== 'vehicle'}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="cost"
									label="Cost"
									labelClass="text-underline"
									name="cost"
									title="For vehicle items, this represents a portion of the vehicle cost to put on this part (should not exceed remaining vehicle cost)&#013For misc items, this represents an approximate value for the item (has no effect)&#013For replenishable items, this is the replacement cost for this item from the default purchase vendor. Note: expense on any particular order may vary."
									disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.costEditable}
									required={!!settingValues.inventory.costRequired}
									bind:value={part.cost}
									on:change={updateDefaultPricing}
								/>
							</div>
						{/if}
						{#if shouldShowPriceField(part.coreCost, part.replenishable, showAllPriceFields) && settingValues.inventory.coreCostVisible}
							<div class="col-6 col-xl-3">
								<CurrencyInput
									id="core-cost"
									label="Core Cost"
									labelClass="text-underline"
									name="coreCost"
									title="The replacement core cost for this item from the default purchase vendor (if any). Note: core expense on any particular order may vary."
									disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.coreCostEditable}
									required={!!settingValues.inventory.coreCostRequired}
									bind:value={part.coreCost}
									on:change={() => {
										if (Number(part.coreCost) !== 0) {
											part.coreRequiredToVendor = true
										}
										updateDefaultPricing()
									}}
								/>
							</div>
						{/if}
					{/if}
					{#if shouldShowPriceField(part.distributorPrice, part.replenishable, showAllPriceFields) && settingValues.inventory.distributorPriceVisible}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="distributor-price"
								label="Distributor"
								labelClass="text-underline"
								name="distributorPrice"
								title="The price to charge any customer set up to pay 'distributor' pricing levels.&#013This generally represents the lowest markup price level on an inventory record."
								bind:value={part.distributorPrice}
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.distributorPriceEditable}
								required={!!settingValues.inventory.distributorPriceRequired}
							/>
						</div>
					{/if}
					{#if shouldShowPriceField(part.distributorCorePrice, part.replenishable, showAllPriceFields) && settingValues.inventory.distributorCorePriceVisible}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="distributor-core-price"
								label="Distributor Core"
								labelClass="text-underline"
								name="distributorCorePrice"
								title="The core charge for any customer set up to pay 'distributor' pricing levels (if any)."
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.distributorCorePriceEditable}
								required={!!settingValues.inventory.distributorPriceRequired}
								bind:value={part.distributorCorePrice}
							/>
						</div>
					{/if}
					{#if settingValues.inventory.jobberPriceVisible}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="jobber-price"
								label="Jobber"
								labelClass="text-underline"
								name="jobberPrice"
								title="The price to charge any customer set up to pay 'jobber' pricing levels.&#013This generally represents a markup level between distributor and wholesale."
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.jobberPriceEditable}
								required={!!settingValues.inventory.jobberPriceRequired}
								bind:value={part.jobberPrice}
							></CurrencyInput>
						</div>
					{/if}
					{#if shouldShowPriceField(part.jobberCorePrice, part.replenishable, showAllPriceFields) && settingValues.inventory.jobberCorePriceVisible}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="jobber-core-price"
								label="Jobber Core"
								labelClass="text-underline"
								name="jobberCorePrice"
								title="The core charge for any customer set up to pay 'jobber' pricing levels (if any)."
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.jobberCorePriceEditable}
								required={!!settingValues.inventory.jobberCorePriceRequired}
								bind:value={part.jobberCorePrice}
							/>
						</div>
					{/if}
					{#if settingValues.inventory.wholesalePriceVisible}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="wholesale-price"
								label="Wholesale"
								labelClass="text-underline"
								name="wholesalePrice"
								title="The price to charge any customer set up to pay 'wholesale' pricing levels.&#013This generally represents a markup level between jobber and retail"
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.wholesalePriceEditable}
								required={!!settingValues.inventory.wholesalePriceRequired}
								bind:value={part.wholesalePrice}
							/>
						</div>
					{/if}
					{#if shouldShowPriceField(part.wholesaleCorePrice, part.replenishable, showAllPriceFields) && settingValues.inventory.wholesaleCorePriceVisible}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="wholesale-core-price"
								label="Wholesale Core"
								labelClass="text-underline"
								name="wholesaleCorePrice"
								title="The core charge for any customer set up to pay 'wholesale' pricing levels (if any)."
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.wholesaleCorePriceEditable}
								required={!!settingValues.inventory.wholesaleCorePriceRequired}
								bind:value={part.wholesaleCorePrice}
							/>
						</div>
					{/if}
					{#if settingValues.inventory.retailPriceVisible}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="retail-price"
								label="Retail"
								labelClass="text-underline"
								name="retailPrice"
								title="The price to charge any customer set up to pay 'retail' pricing levels (the default)."
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.retailPriceEditable}
								required={!!settingValues.inventory.retailPriceRequired}
								bind:value={part.retailPrice}
							/>
						</div>
					{/if}
					{#if settingValues.inventory.retailCorePriceVisible && entityContext !== 'vehicle'}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="retail-core-price"
								label="Retail Core"
								labelClass="text-underline"
								name="retailCorePrice"
								title="The core charge for any customer set up to pay 'retail' pricing levels (if any)."
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.retailCorePriceEditable}
								required={!!settingValues.inventory.retailCorePriceRequired}
								bind:value={part.retailCorePrice}
								on:change={() => {
									if (Number(part.retailCorePrice) !== 0) {
										part.coreRequired = true
									}
								}}
							></CurrencyInput>
						</div>
					{/if}
					{#if settingValues.inventory.listPriceVisible}
						<div class="col-6 col-xl-3">
							<CurrencyInput
								id="list-price"
								label="List"
								labelClass="text-underline"
								name="list"
								title="The chosen list price for this item. This may be used in advertising and/or may be the manufacturer or suppliers suggested price."
								disabled={!hasPermission('PARTS_CAN_EDIT_PRICES', part.storeId) || disableGlobalFields || !settingValues.inventory.listPriceEditable}
								required={!!settingValues.inventory.listPriceRequired}
								bind:value={part.listPrice}
							/>
						</div>
					{/if}
					<div class="col-6 col-xl-3">
						<Input
							readonly
							class="text-right"
							label="HTP Appraised"
							title={appraisalDetails}
							tabindex={-1}
							value={$appraisalQuery.fetching ? 'Loading...' : currencyFormat(appraisal?.value ?? '')}
						>
							<svelte:fragment slot="append">
								<Button
									isLoading={$appraisalQuery.fetching}
									iconClass="redo"
									on:click={() => appraise(true)}
								></Button>
							</svelte:fragment>
						</Input>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
