export default {
    "name": "SaveFavoriteReports",
    "kind": "HoudiniMutation",
    "hash": "f2bae46d7276814dc9a8b89090dca8a6d22b3b3836633da93e22a4b8b0889144",

    "raw": `mutation SaveFavoriteReports($input: SetUserSettingInput!) {
  setUserSetting(input: $input)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setUserSetting": {
                "type": "Boolean",
                "keyRaw": "setUserSetting(input: $input)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "SetUserSettingInput"
        },

        "types": {
            "SetUserPartsSettingInput": {
                "clearScreenOnSave": "Boolean",
                "defaultNewAttachmentsArePublic": "Boolean"
            },

            "SetUserReportViewerSettingInput": {
                "reportNames": "String"
            },

            "SetUserReportViewerHistorySettingInput": {
                "pageSize": "Int",
                "showCancelledJobs": "Boolean",
                "showDelayedJobsOnly": "Boolean",
                "showFailedJobs": "Boolean",
                "showReadyJobs": "Boolean",
                "showSentJobs": "Boolean"
            },

            "SetUserPartSearchSettingInput": {
                "pinnedSearchFields": "String"
            },

            "SetUserVehicleSearchSettingInput": {
                "pinnedSearchFields": "String"
            },

            "SetUserVehiclesSettingInput": {
                "defaultNewAttachmentsArePublic": "Boolean"
            },

            "SetUserSettingInput": {
                "parts": "SetUserPartsSettingInput",
                "reportViewer": "SetUserReportViewerSettingInput",
                "reportViewerHistory": "SetUserReportViewerHistorySettingInput",
                "searchParts": "SetUserPartSearchSettingInput",
                "searchVehicles": "SetUserVehicleSearchSettingInput",
                "vehicles": "SetUserVehiclesSettingInput"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b4497f054ebcb5524bded9ccca9f7024f5ae1f0d936b09fb61cdb569d3bcaabc";