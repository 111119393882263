import { booleanToString } from '@isoftdata/utility-boolean'
//types
import type {
	Models$result,
	Locations$result,
	SideEnum$options,
	Categories$result,
	UserStatuses$input,
	UserStatuses$result,
	InventoryTypes$result,
	PartSearchData$result,
	InventoryQuestions$result,
	InventoryConditions$result,
	UserAccounts$result,
	StringFilter,
} from '$houdini'
import type { Column } from '@isoftdata/svelte-table'

type Session = NonNullable<PartSearchData$result['session']>
type SessionUserAccount = NonNullable<Session['userAccount']>
export type SearchModule = SessionUserAccount['searchModules'][0]
export type Module = SearchModule['module']
export type Store = PartSearchData$result['userAccount']['authorizedStores'][0]
export type AdditionalField = PartSearchData$result['inventoryFields'][0]

import { graphql } from '$houdini'

const getInventoryTypes = graphql(`
	query InventoryTypes($active: Boolean, $vehicle: Boolean) {
		inventoryTypes(filter: { active: $active, vehicle: $vehicle }) {
			name
			setId
			id
			typeLabel1
			typeLabel2
			typeLabel3
			typeLabel4
		}
	}
`)

const getInventoryType = graphql(`
	query InventoryType($inventoryTypeId: Int!) {
		inventoryType(id: $inventoryTypeId) {
			manufacturers {
				id
				name
			}
		}
	}
`)

const getVehicleManufacturers = graphql(`
	query VehicleManufacturers {
		vehicleManufacturers {
			name
			id
		}
	}
`)

const getModels = graphql(`
	query Models($filter: ModelFilter) {
		models(filter: $filter) {
			name
			id
			active
		}
	}
`)

const getLocations = graphql(`
	query Locations($filter: LocationFilter) {
		locations(filter: $filter) {
			id
			name
		}
	}
`)

const getConditions = graphql(`
	query InventoryConditions {
		inventoryConditions
	}
`)

const getCategories = graphql(`
	query Categories($inventoryTypeId: Int!) {
		inventoryType(id: $inventoryTypeId) {
			categories {
				id
				name
				description
			}
		}
	}
`)

const getUserStatuses = graphql(`
	query UserStatuses($type: UserStatusTypeEnum) {
		userStatuses(type: $type) {
			status
			description
		}
	}
`)

const getInventoryQuestions = graphql(`
	query InventoryQuestions($modelId: [Int!], $manufacturerId: [Int!], $inventoryTypeId: [Int!], $categoryName: [String!]) {
		inventoryOptions(modelId: $modelId, manufacturerId: $manufacturerId, inventoryTypeId: $inventoryTypeId, categoryName: $categoryName) {
			id
			name
			dataType
			choices {
				id
				label
			}
		}
	}
`)

const getUserAccounts = graphql(`
	query UserAccounts($filter: UserAccountFilter) {
		userAccounts(filter: $filter) {
			id
			name
		}
	}
`)

export type InventoryType = InventoryTypes$result['inventoryTypes'][0]
export const loadInventoryTypes = async ({ active = true, vehicle }: { active?: boolean; vehicle?: boolean } = {}) => {
	const result = await getInventoryTypes.fetch({ variables: { active, vehicle }, policy: 'CacheOrNetwork' })
	return result.data?.inventoryTypes ?? []
}

export type Manufacturer = {
	id: number
	name: string
}
export const loadVehicleManufacturers = async (): Promise<Manufacturer[]> => {
	const result = await getVehicleManufacturers.fetch({ policy: 'CacheOrNetwork' })
	return result.data?.vehicleManufacturers ?? []
}

export const loadPartManufacturersForInventoryType = async (inventoryTypeId: number): Promise<Manufacturer[]> => {
	const result = await getInventoryType.fetch({ variables: { inventoryTypeId }, policy: 'CacheOrNetwork' })
	return result.data?.inventoryType?.manufacturers ?? []
}

export type Model = Models$result['models'][0]
export const loadPartModelsForInventoryTypeAndManufacturer = async ({ inventoryTypeId, manufacturerId }: { inventoryTypeId?: number; manufacturerId?: number }) => {
	const result = await getModels.fetch({ variables: { filter: { inventoryTypeId, manufacturerId, active: true } }, policy: 'CacheOrNetwork' })
	return result.data?.models ?? []
}

export type InventoryLocation = Locations$result['locations'][0]
export const loadLocationsForStore = async (stores: number | number[]) => {
	const storeIds = Array.isArray(stores) ? stores : [stores]
	const result = await getLocations.fetch({ variables: { filter: { storeIds } }, policy: 'CacheOrNetwork' })
	return result.data?.locations ?? []
}

export type Condition = InventoryConditions$result['inventoryConditions'][0]
export const loadConditions = async () => {
	const result = await getConditions.fetch({ policy: 'CacheOrNetwork' })
	return result.data?.inventoryConditions ?? []
}

export type Category = Categories$result['inventoryType']['categories'][0]
export const loadCategories = async (inventoryTypeId: number) => {
	const result = await getCategories.fetch({ variables: { inventoryTypeId }, policy: 'CacheOrNetwork' })
	return result.data?.inventoryType?.categories ?? []
}

export type UserStatus = UserStatuses$result['userStatuses'][0]
export const loadUserStatuses = async (type: UserStatuses$input['type']) => {
	const result = await getUserStatuses.fetch({ variables: { type }, policy: 'CacheOrNetwork' })
	return result.data?.userStatuses ?? []
}

export type InventoryQuestion = InventoryQuestions$result['inventoryOptions'][0]
interface InventoryQuestionsFilterInput {
	inventoryTypeId?: number | null
	manufacturerId?: number | null
	modelId?: number | null
	category?: string | null
}
export const loadInventoryQuestions = async ({ inventoryTypeId, manufacturerId, modelId, category }: InventoryQuestionsFilterInput) => {
	const result = await getInventoryQuestions.fetch({
		variables: {
			inventoryTypeId: inventoryTypeId ? [inventoryTypeId] : null,
			manufacturerId: manufacturerId ? [manufacturerId] : null,
			modelId: modelId ? [modelId] : null,
			categoryName: category ? [category] : null,
		},
		policy: 'CacheOrNetwork',
	})
	return result.data?.inventoryOptions ?? []
}
export type UserAccount = UserAccounts$result['userAccounts'][0]
export const loadUserAccounts = async () => {
	const result = await getUserAccounts.fetch({
		variables: {
			filter: {
				status: 'ACTIVE',
			},
		},
		policy: 'CacheOrNetwork',
	})
	return result.data?.userAccounts ?? []
}

export function sortSearchFields(a: SearchField, b: SearchField) {
	const aLower = a.name.toLowerCase()
	const bLower = b.name.toLowerCase()

	if (aLower < bLower) {
		return -1
	} else if (aLower > bLower) {
		return 1
	}

	return 0
}

export type Status = '@' | 'A' | 'H' | 'S' | 'C' | 'D' | 'T' | 'B'
export interface StatusOption {
	abbrev: Status
	description: string
}
export type UserStatusType = UserStatuses$input['type']
export type Side = SideEnum$options
export interface SideOption {
	label: string
	value: Side
}
export type StaticSearchFieldKey =
	| 'STORE'
	| 'INVENTORY_TYPE'
	| 'ASSEMBLY_MANUFACTURER'
	| 'ASSEMBLY_MODEL'
	| 'PART_MANUFACTURER'
	| 'PART_MODEL'
	| 'LOCATION'
	| 'CONDITION'
	| 'STATUS'
	| 'USER_STATUS'
	| 'CATEGORY'
	| 'SIDE'
	| 'YEAR_RANGE'
	| 'VEHICLE_MANUFACTURER'
	| 'VEHICLE_MODEL'
	| 'SMART_SEARCH'
	| 'STOCK_NUMBER'
	| 'VIN'
	| 'ADJUSTER'
	| 'BODY_STYLE'
	| 'CLAIM_NUMBER'
	| 'JUNKING_NUMBER'
	| 'FILE_NUMBER'
	| 'EXTERNAL_COLOR'
	| 'ENTERED_BY_USER'
	| 'DISMANTLER'
	| 'DISMANTLED'
	| 'DESCRIPTION'
	| 'DATE_ENTERED'
	| 'PICKUP_LOCATION'
	| 'PURCHASED_FROM'
	| 'RETAIL_PRICE'
	| 'SELLER_CONTACT'
	| 'SELLER_INFO'
	| 'TITLE_STATUS'
	| 'TITLE_NUMBER'
	| 'TITLE_TYPE'
	| 'TAG_NUMBER'

export interface SearchField {
	key: string
	name: string
	additionalFieldData?: AdditionalField
	qaFieldData?: InventoryQuestion
	dependencies: StaticSearchFieldKey[]
	dependants?: StaticSearchFieldKey[]
	alwaysVisible?: boolean
}

export type SearchFieldValue =
	| string
	| number
	| number[] //store
	| InventoryType
	| DateRange
	| StringRange
	| NumberRange
	| CurrencyRange
	| SideOption
	| UserStatus
	| InventoryLocation
	| Manufacturer
	| Model
	| Category
	| Status
	| SmartSearch
	| AdditionalField
	| InventoryQuestion
	| null

interface StaticSearchFieldValue {
	STORE: number[]
	INVENTORY_TYPE: InventoryType | null
	STATUS: Status | null
	USER_STATUS: UserStatus | null
	YEAR_RANGE: NumberRange
	VEHICLE_MANUFACTURER: Manufacturer | null
	VEHICLE_MODEL: Model | null
	CATEGORY: Category | null
	CONDITION: Condition | null
	LOCATION: InventoryLocation | null
}

export interface InventoryStaticSearchFieldValue extends StaticSearchFieldValue {
	ASSEMBLY_MANUFACTURER: Manufacturer | null
	ASSEMBLY_MODEL: Model | null
	PART_MANUFACTURER: Manufacturer | null
	PART_MODEL: Model | null
	SIDE: SideOption | null
	SMART_SEARCH: SmartSearch
}

export interface VehicleStaticSearchFieldValue extends StaticSearchFieldValue {
	STOCK_NUMBER: string | null
	VIN: string | null
	ADJUSTER: string | null
	BODY_STYLE: string | null
	CLAIM_NUMBER: string | null
	JUNKING_NUMBER: string | null
	FILE_NUMBER: string | null
	EXTERNAL_COLOR: string | null
	ENTERED_BY_USER: UserAccount | null
	DISMANTLER: UserAccount | null
	DISMANTLED: boolean | undefined
	DESCRIPTION: string | null
	DATE_ENTERED: DateRange
	PICKUP_LOCATION: InventoryLocation | null
	PURCHASED_FROM: string | null
	RETAIL_PRICE: CurrencyRange | null
	SELLER_CONTACT: string | null
	SELLER_INFO: string | null
	TITLE_STATUS: string | null
	TITLE_NUMBER: string | null
	TITLE_TYPE: string | null
}

export type GetterFn<ValueType> = (value: ValueType) => SearchFieldValue
export interface StaticSearchField extends SearchField {
	key: StaticSearchFieldKey
	dbFieldKey?: string[]
	valueGetter?:
		| GetterFn<string>
		| GetterFn<number>
		| GetterFn<boolean>
		| GetterFn<number[]>
		| GetterFn<NumberRange>
		| GetterFn<InventoryType>
		| GetterFn<Manufacturer>
		| GetterFn<Model>
		| GetterFn<SideOption>
		| GetterFn<Category>
		| GetterFn<InventoryLocation>
		| GetterFn<SmartSearch>
		| GetterFn<Status>
		| GetterFn<UserStatus>
		| GetterFn<UserAccount>
}

export interface StringRange {
	from: string | undefined
	to: string | undefined
}
export interface DateRange {
	from: string | null
	to: string | null
}
export interface CurrencyRange {
	from: string
	to: string
}
export interface NumberRange {
	from: number | null
	to: number | null
}
export interface SmartSearch {
	keyword: string
	moduleIds: Array<Module['id']>
}
export type AdditionalFieldValue = NumberRange | StringRange | CurrencyRange | DateRange | number | string | boolean | null
export interface InventoryQuestionChoice {
	id: number
	label: string
}
export type InventoryQuestionValue = string | boolean | number | InventoryQuestionChoice | null

const staticCommonSearchFields: StaticSearchField[] = [
	{
		key: 'STORE',
		name: 'Stores',
		dependencies: [],
		dbFieldKey: ['inventory.storeid'],
		alwaysVisible: true,
		valueGetter: (stores: number[]) => (stores ? JSON.stringify(stores) : ''),
	},
	{
		key: 'INVENTORY_TYPE',
		name: 'Inventory Type',
		dependencies: [],
		dbFieldKey: ['inventory.inventorytypeid', 'inventorytype.name'],
		valueGetter: (inventoryType: InventoryType) => (inventoryType ? JSON.stringify(inventoryType.id) : ''),
	},
	{
		key: 'STATUS',
		name: 'Status',
		dependencies: [],
		dbFieldKey: ['inventory.status'],
		alwaysVisible: true,
		valueGetter: (status: Status) => status || '@',
	},
	{
		key: 'USER_STATUS',
		name: 'User Status',
		dependencies: [],
		dbFieldKey: ['inventory.userstatus'],
		valueGetter: (userStatus: UserStatus) => userStatus?.status ?? null,
	},
	{
		key: 'CATEGORY',
		name: 'Category',
		dependencies: ['INVENTORY_TYPE'],
		dbFieldKey: ['inventory.category'],
		valueGetter: (category: Category) => category?.name ?? '',
	},
	{
		key: 'YEAR_RANGE',
		name: 'Year (Range)',
		dependencies: [],
		dbFieldKey: ['inventory.year'],
		valueGetter: (yearRange: NumberRange) => JSON.stringify(yearRange),
	},
	{
		key: 'VEHICLE_MANUFACTURER',
		name: 'Vehicle Make',
		dependencies: [],
		dbFieldKey: ['inventory.make'],
		valueGetter: (manufacturer: Manufacturer) => (manufacturer ? JSON.stringify(manufacturer.id) : ''),
	},
	{
		key: 'VEHICLE_MODEL',
		name: 'Vehicle Model',
		dependencies: ['VEHICLE_MANUFACTURER'],
		dbFieldKey: ['model.name'],
		valueGetter: (model: Model) => (model ? JSON.stringify(model.id) : ''),
	},
	{
		key: 'LOCATION',
		name: 'Location',
		dependencies: ['STORE'],
		dbFieldKey: ['inventorylocation.location', 'inventory.location'],
		valueGetter: (location: InventoryLocation) => (location ? location.name : ''),
	},
]

export const staticVehicleSearchFields: StaticSearchField[] = [
	...staticCommonSearchFields,
	{
		key: 'STOCK_NUMBER',
		name: 'Stock Number',
		dependencies: [],
		valueGetter: (stockNumber: string) => stockNumber || '',
	},
	{
		key: 'VIN',
		name: 'VIN',
		dependencies: [],
		valueGetter: (vin: string) => vin || '',
	},
	{
		key: 'ADJUSTER',
		name: 'Adjuster',
		dependencies: [],
		valueGetter: (adjuster: string) => adjuster || '',
	},
	{
		key: 'BODY_STYLE',
		name: 'Body Style',
		dependencies: [],
		valueGetter: (bodyStyle: string) => bodyStyle || '',
	},
	{
		key: 'CLAIM_NUMBER',
		name: 'Claim Number',
		dependencies: [],
		valueGetter: (claimNumber: string) => claimNumber || '',
	},
	{
		key: 'JUNKING_NUMBER',
		name: 'Junking Number',
		dependencies: [],
		valueGetter: (junkingNumber: string) => junkingNumber || '',
	},
	{
		key: 'FILE_NUMBER',
		name: 'File Number',
		dependencies: [],
		valueGetter: (fileNumber: string) => fileNumber || '',
	},
	{
		key: 'EXTERNAL_COLOR',
		name: 'External Color',
		dependencies: [],
		valueGetter: (externalColor: string) => externalColor || '',
	},
	{
		key: 'ENTERED_BY_USER',
		name: 'Entered By User',
		dependencies: [],
		valueGetter: (enteredByUser: UserAccount) => enteredByUser?.id || '',
	},
	{
		key: 'DISMANTLER',
		name: 'Dismantler',
		dependencies: [],
		valueGetter: (dismantler: UserAccount) => dismantler?.id || '',
	},
	{
		key: 'DISMANTLED',
		name: 'Dismantled',
		dependencies: [],
		valueGetter: (dismantled: boolean) => JSON.stringify(dismantled) || '',
	},
	{
		key: 'DESCRIPTION',
		name: 'Description',
		dependencies: [],
		valueGetter: (description: string) => description || '',
	},
	{
		key: 'DATE_ENTERED',
		name: 'Date Entered',
		dependencies: [],
		valueGetter: (dateEntered: string) => JSON.stringify(dateEntered),
	},
	{
		key: 'PICKUP_LOCATION',
		name: 'Pickup Location',
		dependencies: [],
		valueGetter: (pickupLocation: string) => pickupLocation || '',
	},
	{
		key: 'PURCHASED_FROM',
		name: 'Purchased From',
		dependencies: [],
		valueGetter: (purchasedFrom: string) => purchasedFrom || '',
	},
	{
		key: 'RETAIL_PRICE',
		name: 'Retail Price',
		dependencies: [],
		valueGetter: (retailPrice: string) => JSON.stringify(retailPrice),
	},
	{
		key: 'SELLER_CONTACT',
		name: 'Seller Contact',
		dependencies: [],
		valueGetter: (sellerContact: string) => sellerContact || '',
	},
	{
		key: 'SELLER_INFO',
		name: 'Seller Info',
		dependencies: [],
		valueGetter: (sellerInfo: string) => sellerInfo || '',
	},
	{
		key: 'TITLE_STATUS',
		name: 'Title Status',
		dependencies: [],
		valueGetter: (titleStatus: string) => titleStatus || '',
	},
	{
		key: 'TITLE_NUMBER',
		name: 'Title Number',
		dependencies: [],
		valueGetter: (titleNumber: string) => titleNumber || '',
	},
	{
		key: 'TITLE_TYPE',
		name: 'Title Type',
		dependencies: [],
		valueGetter: (titleType: string) => titleType || '',
	},
]

export const staticInventorySearchFields: StaticSearchField[] = [
	...staticCommonSearchFields,
	{
		key: 'ASSEMBLY_MANUFACTURER',
		name: 'Assembly Manufacturer',
		dependencies: ['INVENTORY_TYPE'],
		dbFieldKey: ['inventory.parentmanufacturerid', 'parentmanufacturer.name'],
		valueGetter: (manufacturer: Manufacturer) => (manufacturer ? JSON.stringify(manufacturer.id) : ''),
	},
	{
		key: 'ASSEMBLY_MODEL',
		name: 'Assembly Model',
		dependencies: ['INVENTORY_TYPE', 'ASSEMBLY_MANUFACTURER'],
		dbFieldKey: ['inventory.parentmodelid', 'parentmodel.name'],
		valueGetter: (model: Model) => (model ? JSON.stringify(model.id) : ''),
	},
	{
		key: 'PART_MANUFACTURER',
		name: 'Part Manufacturer',
		dependencies: ['INVENTORY_TYPE'],
		dbFieldKey: ['inventory.parentmanufacturerid', 'parentmanufacturer.name'],
		valueGetter: (manufacturer: Manufacturer) => (manufacturer ? JSON.stringify(manufacturer.id) : ''),
	},
	{
		key: 'PART_MODEL',
		name: 'Part Model',
		dependencies: ['INVENTORY_TYPE', 'PART_MANUFACTURER'],
		dbFieldKey: ['inventory.manufacturerid', 'manufacturer.name'],
		valueGetter: (model: Model) => (model ? JSON.stringify(model.id) : ''),
	},
	{
		key: 'CONDITION',
		name: 'Condition',
		dependencies: [],
		dbFieldKey: ['inventory.condition'],
		valueGetter: (condition: Condition) => condition,
	},
	{
		key: 'SIDE',
		name: 'Side',
		dependencies: [],
		dbFieldKey: ['inventory.side'],
		valueGetter: (side: SideOption) => side?.value,
	},
	{
		key: 'SMART_SEARCH',
		name: 'Smart Search',
		dependencies: [],
		valueGetter: (smartSearch: SmartSearch) => JSON.stringify(smartSearch),
	},
	{
		key: 'TAG_NUMBER',
		name: 'Tag #',
		dependencies: [],
		dbFieldKey: ['inventory.tagnumber'],
		valueGetter: (tagNumber: string) => (tagNumber ? tagNumber : ''),
	},
]

export function getStaticSearchFieldsWithDependencyGraph(fieldType: 'INVENTORY' | 'VEHICLE' = 'INVENTORY') {
	const staticSearchFieldDependentMap: Map<StaticSearchFieldKey, StaticSearchFieldKey[]> = new Map()
	const staticSearchFieldDependencyMap: Map<StaticSearchFieldKey, StaticSearchFieldKey[]> = new Map()

	const staticSearchFields = fieldType === 'INVENTORY' ? staticInventorySearchFields : staticVehicleSearchFields

	for (const field of staticSearchFields) {
		staticSearchFieldDependencyMap.set(field.key, field.dependencies)
	}

	for (const [key, dependencies] of staticSearchFieldDependencyMap) {
		for (const dependency of dependencies) {
			if (staticSearchFieldDependentMap.has(dependency)) {
				staticSearchFieldDependentMap.set(dependency, [...(staticSearchFieldDependentMap.get(dependency) ?? []), key])
			} else {
				staticSearchFieldDependentMap.set(dependency, [key])
			}
		}
	}

	return staticSearchFields.map(field => {
		return {
			...field,
			dependants: staticSearchFieldDependentMap.get(field.key) ?? [],
		}
	})
}
export interface SearchFieldCategory {
	name: string
	fields: SearchField[]
}
export interface SearchFieldsByCategory {
	STANDARD?: SearchFieldCategory
	CUSTOM?: SearchFieldCategory
}
//This functions puts search fields into categories for use in the "Add Search Field" select dropdown
export function getSearchFieldsByCategory(
	staticSearchFields: StaticSearchField[],
	typeFields: SearchField[],
	inventoryQuestions: SearchField[],
	additionalFields?: SearchField[],
): SearchFieldsByCategory {
	return {
		STANDARD: {
			name: 'Standard Fields',
			fields: [...staticSearchFields, ...(additionalFields ?? [])].slice().sort(sortSearchFields),
		},
		CUSTOM: {
			name: 'Custom Fields',
			fields: [...typeFields, ...inventoryQuestions].slice().sort(sortSearchFields),
		},
	}
}

export function getInitialSelectedSearchFields(staticSearchFields: StaticSearchField[], userPinnedSearchFields: string[]) {
	//This is the list of the selected search fields
	const selectedSearchFieldsKeys: Set<string> = new Set(['STORE', 'INVENTORY_TYPE'])
	//Add the always visible fields
	for (const staticSearchField of staticSearchFields) {
		if (staticSearchField.alwaysVisible) {
			selectedSearchFieldsKeys.add(staticSearchField.key)
		}
	}
	//Add the user's pinned search fields
	for (const pinnedSearchField of userPinnedSearchFields) {
		selectedSearchFieldsKeys.add(pinnedSearchField)
	}

	return selectedSearchFieldsKeys
}

export function getValidTypeFields(inventoryType?: InventoryType | null) {
	if (!inventoryType) return []

	const typeFields: SearchField[] = [
		{
			key: 'TYPE_FIELD_1',
			name: inventoryType.typeLabel1 ?? '',
			dependencies: [],
		},
		{
			key: 'TYPE_FIELD_2',
			name: inventoryType.typeLabel2 ?? '',
			dependencies: [],
		},
		{
			key: 'TYPE_FIELD_3',
			name: inventoryType.typeLabel3 ?? '',
			dependencies: [],
		},
		{
			key: 'TYPE_FIELD_4',
			name: inventoryType.typeLabel4 ?? '',
			dependencies: [],
		},
	]

	return typeFields.filter(field => !!field.name)
}

export function buildAdditionalFieldValueFilterArgument(value: AdditionalFieldValue): StringFilter {
	if (typeof value === 'object' && value !== null && (value?.from || value?.to)) {
		let filter = {}
		if (value.from) {
			filter = { ...filter, gte: value.from.toString() }
		}
		if (value.to) {
			filter = { ...filter, lte: value.to.toString() }
		}
		return filter
	} else if (typeof value === 'boolean') {
		return { eq: booleanToString(value) }
	} else {
		return { eq: value?.toString() ?? '' }
	}
}

export function buildInventoryQuestionValueFilterArgument(value: InventoryQuestionValue): StringFilter {
	if (typeof value === 'boolean') {
		return { eq: booleanToString(value) }
	} else {
		return { eq: value?.toString() ?? '' }
	}
}

//This is pretty general and should probably go somewhere else, but idk where yet
export const saveUserSetting = graphql(`
	mutation SetUserSetting($input: SetUserSettingInput!) {
		setUserSetting(input: $input)
	}
`)

function isValidSearchKey(key: string, selectedSearchFieldsKeys: Set<string>): key is StaticSearchFieldKey {
	return selectedSearchFieldsKeys.has(key)
}

export function computeSearchParameterValues(
	selectedSearchFieldsKeys: Set<string>,
	staticSearchFieldValues: InventoryStaticSearchFieldValue | VehicleStaticSearchFieldValue,
	additionalFieldValues: Record<string, AdditionalFieldValue>,
	typeFieldValues: Record<string, string>,
	inventoryQuestionValues: Record<string, InventoryQuestionValue>,
	staticSearchFieldsMap: Map<StaticSearchFieldKey, StaticSearchField>,
): Record<string, unknown> {
	const staticFields = Object.fromEntries(
		Object.entries(staticSearchFieldValues).reduce((acc, [key, value]) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			if (selectedSearchFieldsKeys.has(key) && isValidSearchKey(key, selectedSearchFieldsKeys) && (value || typeof value === 'boolean') && staticSearchFieldsMap.get(key)?.valueGetter?.(value)) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				acc.push([key, staticSearchFieldsMap.get(key)?.valueGetter?.(value)])
			}
			return acc
		}, []),
	)

	const additionalFields = Object.fromEntries(
		Object.entries(additionalFieldValues)
			.filter(([key, value]) => selectedSearchFieldsKeys.has(key) && value)
			.map(([key, value]) => [key.replace('ADDITIONAL_', ''), value]),
	)

	const typeFields = Object.fromEntries(Object.entries(typeFieldValues).filter(([key, value]) => selectedSearchFieldsKeys.has(key) && value))

	const qaFields = Object.fromEntries(
		Object.entries(inventoryQuestionValues)
			.filter(([key, value]) => selectedSearchFieldsKeys.has(key) && value)
			.map(([key, value]) => [key.replace('QA_', ''), value]),
	)

	return {
		...staticFields,
		...typeFields,
		ADDITIONAL_FIELDS: JSON.stringify(additionalFields),
		QA_FIELDS: JSON.stringify(qaFields),
	}
}

export type PerPageCount = '10' | '25' | '50' | '100'
export const perPageCounts: PerPageCount[] = ['10', '25', '50', '100']
export function isValidPerPageCount(perPageCount: string): perPageCount is PerPageCount {
	return perPageCounts.includes(perPageCount as PerPageCount)
}

export function computeTypeLabelColumns(typeId: number | undefined, inventoryTypes: Pick<InventoryTypes$result['inventoryTypes'][0], 'name'>[]) {
	let typeLabelColumns: Column[] = []

	//if inventoryTypeId is truthy, that means they searched by an inventory type, which means we can show the type fields labels
	if (typeId && inventoryTypes?.length > 0) {
		typeLabelColumns = Object.entries(inventoryTypes[0]).reduce((acc: Column[], [key, label]) => {
			if (key.startsWith('typeLabel') && label) {
				acc.push({ property: key.replace('Label', 'Field'), name: label ? label?.toString() : '' })
			}
			return acc
		}, [])
	} else {
		typeLabelColumns = [
			{ property: 'typeField1', name: 'Type Field 1' },
			{ property: 'typeField2', name: 'Type Field 2' },
			{ property: 'typeField3', name: 'Type Field 3' },
			{ property: 'typeField4', name: 'Type Field 4' },
		]
	}

	return typeLabelColumns
}
