import type { AppContext, ResolveParameters } from 'types/common'
import component from './ReportSubscriptions.svelte'
import { graphql } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import { getSession } from 'stores/session'
import { stringToBoolean } from '@isoftdata/utility-string'

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.report-subscriptions',
		route: 'report-subscriptions',
		defaultParameters: {
			pageNumber: '1',
			showOnlyMine: 'true',
		},
		querystringParameters: ['pageNumber', 'showOnlyMine'],
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, parameters: ResolveParameters<{ pageNumber: number }>) {
			const session = getSession()
			const showOnlyMine = stringToBoolean(parameters.showOnlyMine)
			const [reportSubscriptionResult, initialPageLoadDataResult] = await Promise.all([
				reportSubscriptionsQuery.fetch({
					variables: {
						reportSubscriptionFilter: {
							createdByUserIds: showOnlyMine ? [session.id] : null,
						},
						pagination: {
							pageSize: 10,
							pageNumber: parseInt(parameters.pageNumber, 10),
						},
					},
				}),
				initialPageLoadDataQuery.fetch({
					variables: {
						userAccountsFilter: {
							status: 'ACTIVE',
						},
						reportPagination: {
							pageSize: 10000,
							pageNumber: 1,
						},
						reportsFilter: {
							userVisible: true,
						},
					},
					policy: 'CacheOrNetwork',
				}),
			])

			const { data } = reportSubscriptionResult
			const { data: initialData } = initialPageLoadDataResult

			if (!data) {
				throw showErrorAndRedirect(mediator, 'Failed to load Report Subscriptions', 'No data returned from server', { name: 'app' })
			}

			if (!initialData) {
				throw showErrorAndRedirect(mediator, 'Failed to load Initial Page Data', 'No data returned from server', { name: 'app' })
			}

			// Loading the parameters for each report subscription seems to be slow down the page load by a lot.
			// So I changed the query to not include the parameters for now, and only load the parameters on its own query when a report subscription is selected
			const reportSubscriptionList = data.reportSubscriptions.items.map(subscription => {
				const report = subscription.report ?? null

				return {
					...subscription,
					report,
					createdByUserName: `${subscription.createdByUser?.firstName ?? ''} ${subscription.createdByUser?.lastName ?? ''}`,
					reportName: report?.name ?? null,
					reportType: report?.type ?? null,
					reportCategory: report?.category ?? null,
					reportDescription: report?.description ?? null,
					sendTo: subscription.destination?.split(/[;,]/) ?? [],
					parameters: [],
				}
			})

			return {
				reportSubscriptionList,
				showOnlyMine,
				reportList: initialData.reports.items,
				userAccounts: initialData.userAccounts.map(user => {
					return {
						...user,
						userGroups: user.groups,
					}
				}),
				groups: initialData.groups.map(group => {
					return {
						...group,
						name: group.name ?? '',
					}
				}),
				stores: initialData.stores,
				printers: initialData.reportQueuePrinters.items,
				perPageCount: data.reportSubscriptions.pageInfo.pageSize,
				totalItemsCount: data.reportSubscriptions.totalItems,
				currentPageNumber: data.reportSubscriptions.pageInfo.pageNumber,
			}
		},
	})
}

const reportSubscriptionsQuery = graphql(`
	query ReportSubscriptions($reportSubscriptionFilter: ReportSubscriptionFilter, $pagination: PaginationOptions) {
		reportSubscriptions(filter: $reportSubscriptionFilter, pagination: $pagination) {
			items {
				id
				createdByUserId
				createdByUser {
					firstName
					lastName
				}
				sourceType
				destinationType
				destination
				title
				emailBody
				emailFrom
				attachmentName
				reportId
				nextInstance
				schedulePeriodType
				schedulePeriod
				active
				dateCreated
				comments
				sourceQuery
				parameterDateRange
				report {
					id
					name
					type
					category
					description
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
			}
			totalItems
		}
	}
`)

const initialPageLoadDataQuery = graphql(`
	query InitialPageLoadData($userAccountsFilter: UserAccountFilter, $reportsFilter: ReportsFilter, $reportPagination: PaginationOptions) {
		reports(filter: $reportsFilter, pagination: $reportPagination) {
			items {
				id
				category
				description
				name
				numberOfParameters
				averageExecutionTime
				type
				userVisible
			}
		}
		groups {
			id
			name
		}
		stores {
			id
			code
			name
		}
		userAccounts(filter: $userAccountsFilter) {
			id
			name
			workEmail
			firstName
			lastName
			authorizedSites: authorizedStores {
				id
			}
			groups {
				id
			}
		}
		reportQueuePrinters {
			items {
				displayName
				name
			}
		}
	}
`)
