import { graphql, type Reports$input, type Reports$result } from '$houdini'
import type { Simplify, WritableDeep } from 'type-fest'

type Report = Simplify<WritableDeep<Reports$result['reports']['items'][number]>>

const reportListQuery = graphql(`
	query Reports($pagination: PaginationOptions, $filter: ReportsFilter) {
		reports(pagination: $pagination, filter: $filter) {
			items {
				id
				type
				category
				name
				userVisible
				description
				numberOfParameters
				averageExecutionTime
			}
		}
	}
`)

export default async function loadReports(variables: Reports$input): Promise<Array<Report>> {
	const { data } = await reportListQuery.fetch({ variables })

	if (!data) {
		return []
	}

	return data.reports.items
}

export const reportParameterQuery = graphql(`
	query GetReportParameters($reportId: UInt!) {
		report(id: $reportId) {
			id
			type
			category
			name
			parameters {
				id
				label
				name
				rank
				type
				visible
				editability
				required
				options {
					isDefault
					id
					label
					value
				}
			}
		}
	}
`)
