export default {
    "name": "InitialPageLoadData",
    "kind": "HoudiniQuery",
    "hash": "7f5c5b33cd6638721926d42a4cf932f71d1ac9442ee6af5dfa9bff93b2478d5f",

    "raw": `query InitialPageLoadData($userAccountsFilter: UserAccountFilter, $reportsFilter: ReportsFilter, $reportPagination: PaginationOptions) {
  reports(filter: $reportsFilter, pagination: $reportPagination) {
    items {
      id
      category
      description
      name
      numberOfParameters
      averageExecutionTime
      type
      userVisible
    }
  }
  groups {
    id
    name
  }
  stores {
    id
    code
    name
  }
  userAccounts(filter: $userAccountsFilter) {
    id
    name
    workEmail
    firstName
    lastName
    authorizedSites: authorizedStores {
      id
    }
    groups {
      id
    }
  }
  reportQueuePrinters {
    items {
      displayName
      name
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reports": {
                "type": "ReportsList",
                "keyRaw": "reports(filter: $reportsFilter, pagination: $reportPagination)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Report",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "numberOfParameters": {
                                        "type": "Int",
                                        "keyRaw": "numberOfParameters",
                                        "visible": true
                                    },

                                    "averageExecutionTime": {
                                        "type": "Int",
                                        "keyRaw": "averageExecutionTime",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "userVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "userVisible",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "groups": {
                "type": "Group",
                "keyRaw": "groups",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "stores": {
                "type": "Store",
                "keyRaw": "stores",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userAccounts": {
                "type": "UserAccount",
                "keyRaw": "userAccounts(filter: $userAccountsFilter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "authorizedSites": {
                            "type": "Store",
                            "keyRaw": "authorizedSites",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "groups": {
                            "type": "Group",
                            "keyRaw": "groups",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "reportQueuePrinters": {
                "type": "ReportQueuePrinterList",
                "keyRaw": "reportQueuePrinters",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "ReportQueuePrinter",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "displayName": {
                                        "type": "String",
                                        "keyRaw": "displayName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userAccountsFilter": "UserAccountFilter",
            "reportsFilter": "ReportsFilter",
            "reportPagination": "PaginationOptions"
        },

        "types": {
            "UserAccountFilter": {
                "isSalesPerson": "Boolean",
                "name": "String",
                "status": "UserAccountStatusEnum"
            },

            "ReportsFilter": {
                "type": "String",
                "userVisible": "Boolean"
            },

            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=51698121917a075660a68488e3acdf27ef237d88eebb1f08d0b525c55baa48ab";