<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'
	import { type ComponentProps, type ComponentEvents, getContext } from 'svelte'
	import { reportParameterQuery } from 'utility/report-viewer-helper'
	import { ReportSubscriptions, type ReportSubscription, type UpdateReportSubscriptionInput } from '@isoftdata/svelte-report-viewer'
	import {
		graphql,
		type CreateReportSubscription$input,
		type CreateReportSubscription$result,
		type UpdateExistingReportSubscription$input,
		type UpdateExistingReportSubscription$result,
		type InitialPageLoadData$result,
	} from '$houdini'

	type SavedReportSubscription = CreateReportSubscription$result['createReportSubscription'] | UpdateExistingReportSubscription$result['updateReportSubscription']
	type GroupTypeReturned = Array<Omit<InitialPageLoadData$result['groups'][number], 'name'> & { name: string }>
	type UserAccountTypeReturned = Array<InitialPageLoadData$result['userAccounts'][number] & { userGroups: GroupTypeReturned }>

	export let asr: SvelteAsr
	export let reportSubscriptionList: ReportSubscription[] = []
	export let showOnlyMine: boolean = true
	export let reportList: InitialPageLoadData$result['reports']['items'] = []
	export let userAccounts: UserAccountTypeReturned = []
	export let plants: InitialPageLoadData$result['stores'] = []
	export let groups: GroupTypeReturned = []
	export let printers: InitialPageLoadData$result['reportQueuePrinters']['items'] = []
	export let perPageCount = 10
	export let currentPageNumber = 1
	export let totalItemsCount: number | undefined = undefined

	const mediator = getContext<Mediator>('mediator')

	let selectedReportSubscription: ReportSubscription | null = null

	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'US/Central'
	const isDateOrDateTime = (type: string) => type === 'DATE' || type === 'DATE_TIME'

	async function getSelectedReportSubscriptionParameters(reportSubscriptionId: number): Promise<ReportSubscription['parameters']> {
		try {
			const { data } = await reportSubscriptionParametersQuery.fetch({
				variables: {
					reportSubscriptionId,
				},
			})
			if (!data) {
				throw new Error('No data returned from reportSubscriptionParametersQuery')
			}

			if (!data.reportSubscriptionParameters) {
				throw new Error('No reportSubscriptionParameters returned from reportSubscriptionParametersQuery')
			}

			return (
				data.reportSubscriptionParameters.items.map(param => {
					return {
						...param,
						required: param.reportParameter.required ?? false,
						type: param.reportParameter.type ?? 'STRING',
						visible: param.reportParameter.visible ?? true,
						label: param.reportParameter.label,
						name: param.reportParameter.name,
						displayValue: null,
						value: param.value ?? '',
						editability: param.reportParameter.editability ?? 'UNRESTRICTED',
						options: param.reportParameter.options.map(option => {
							return {
								id: option.id,
								label: option.label,
								value: option.value ?? null,
							}
						}),
					}
				}) ?? []
			)
		} catch (err) {
			const error = err as Error
			console.error(err)
			mediator.publish('showMessage', {
				heading: 'Error Fetching Report Subscription Parameters',
				message: error.message,
				type: 'danger',
				time: false,
			})
			return []
		}
	}

	async function getReportParameters(reportId: number): Promise<ReportSubscription['parameters']> {
		const { data } = await reportParameterQuery.fetch({
			variables: {
				reportId,
			},
		})

		if (!data) {
			throw new Error('No data returned from reportParameterQuery')
		}

		const reportParameters = data.report?.parameters
		return reportParameters
			? reportParameters.map(parameter => {
					let newParameterValue: string = ''
					parameter.options.map(option => {
						if (option.isDefault) {
							newParameterValue = option.id?.toString() ?? option.label ?? option.value ?? ''
						}
					})
					return {
						...parameter,
						required: parameter.required ?? false,
						type: parameter.type ?? 'STRING',
						visible: parameter.visible ?? true,
						label: parameter.label,
						reportParameterId: parameter.id,
						value: newParameterValue ?? '',
						displayValue: null,
						editability: parameter.editability ?? 'UNRESTRICTED',
						options: parameter.options,
					}
				})
			: []
	}

	function getCreateReportSubscriptionInputs(reportSubscriptionToCreate: ReportSubscription): CreateReportSubscription$input['inputs'] {
		return {
			sourceType: reportSubscriptionToCreate.sourceType,
			destinationType: reportSubscriptionToCreate.destinationType,
			destination: reportSubscriptionToCreate.destination,
			title: reportSubscriptionToCreate.title,
			emailBody: reportSubscriptionToCreate.emailBody,
			emailFrom: reportSubscriptionToCreate.emailFrom,
			attachmentName: reportSubscriptionToCreate.attachmentName,
			reportId: reportSubscriptionToCreate.reportId,
			schedulePeriodType: reportSubscriptionToCreate.schedulePeriodType,
			schedulePeriod: reportSubscriptionToCreate.schedulePeriod,
			comments: reportSubscriptionToCreate.comments,
			nextInstance: new Date(reportSubscriptionToCreate.nextInstance) ?? null,
			sourceQuery: reportSubscriptionToCreate.sourceQuery,
			reportDateRange: reportSubscriptionToCreate.reportId ? reportSubscriptionToCreate.parameterDateRange : null,
			reportSubscriptionParameters: reportSubscriptionToCreate.reportId
				? reportSubscriptionToCreate.parameters.map(parameter => {
						if (isDateOrDateTime(parameter.type) && reportSubscriptionToCreate.parameterDateRange === 'CUSTOM') {
							return {
								reportParameterId: parameter.reportParameterId,
								sqlValue: parameter.value,
							}
						} else {
							return {
								reportParameterId: parameter.reportParameterId,
								rawValue: parameter.value,
							}
						}
					})
				: null,
		}
	}

	function getUpdateReportSubscriptionInputs(reportSubscriptionToUpdate: UpdateReportSubscriptionInput): UpdateExistingReportSubscription$input['inputs'] {
		const inputs = getCreateReportSubscriptionInputs(reportSubscriptionToUpdate)
		return {
			...inputs,
			reportSubscriptionId: reportSubscriptionToUpdate.id,
		}
	}

	function formatReportSubscription(savedReportSubscription: SavedReportSubscription): ReportSubscription {
		return {
			id: savedReportSubscription.id,
			createdByUserName: savedReportSubscription.createdByUser?.firstName + ' ' + savedReportSubscription.createdByUser?.lastName,
			sourceType: savedReportSubscription.sourceType,
			destinationType: savedReportSubscription.destinationType === 'EMAILCSV' ? 'EMAIL' : savedReportSubscription.destinationType,
			destination: savedReportSubscription.destination,
			sendTo: savedReportSubscription.destination?.split(/[;,]/) ?? [],
			title: savedReportSubscription.title,
			emailBody: savedReportSubscription.emailBody,
			emailFrom: savedReportSubscription.emailFrom,
			attachmentName: savedReportSubscription.attachmentName,
			reportId: savedReportSubscription.reportId,
			reportName: savedReportSubscription.report?.name ?? null,
			reportType: savedReportSubscription.report?.type ?? null,
			reportCategory: savedReportSubscription.report?.category ?? null,
			reportDescription: savedReportSubscription.report?.description ?? null,
			nextInstance: savedReportSubscription.nextInstance.toISOString() ?? null,
			schedulePeriodType: savedReportSubscription.schedulePeriodType,
			schedulePeriod: savedReportSubscription.schedulePeriod,
			active: savedReportSubscription.active,
			dateCreated: savedReportSubscription.dateCreated.toISOString(),
			comments: savedReportSubscription.comments ?? '',
			sourceQuery: savedReportSubscription.sourceQuery,
			parameterDateRange: savedReportSubscription.parameterDateRange,
			parameters:
				savedReportSubscription.parameters?.map(parameter => {
					return {
						...parameter.reportParameter,
						required: parameter.reportParameter.required ?? false,
						type: parameter.reportParameter.type ?? 'STRING',
						visible: parameter.reportParameter.visible ?? true,
						reportParameterId: parameter.reportParameterId,
						value: parameter.value ?? '',
						displayValue: null,
						editability: parameter.reportParameter.editability ?? 'UNRESTRICTED',
						options: parameter.reportParameter.options.map(option => {
							return {
								...option,
								label: option.label ?? '',
							}
						}),
					}
				}) ?? [],
		}
	}

	async function createReportSubscription(reportSubscriptionToCreate: ReportSubscription): Promise<ReportSubscription> {
		const inputs = getCreateReportSubscriptionInputs(reportSubscriptionToCreate)
		const { data } = await newReportSubscriptionMutation.mutate({
			inputs,
		})
		if (!data) {
			throw new Error('No data returned from createReportSubscription')
		}
		return formatReportSubscription(data.createReportSubscription)
	}

	async function updateReportSubscription(reportSubscriptionToUpdate: UpdateReportSubscriptionInput): Promise<ReportSubscription> {
		let reportSubscriptionId = reportSubscriptionToUpdate.id
		const inputs = getUpdateReportSubscriptionInputs(reportSubscriptionToUpdate)
		const { data } = await updateReportSubscriptionMutation.mutate({
			inputs: {
				...inputs,
				reportSubscriptionId,
			},
		})
		if (!data) {
			throw new Error('No data returned from updateReportSubscription')
		}
		return formatReportSubscription(data.updateReportSubscription)
	}

	async function updateReportSubscriptionStatus(reportSubscriptionId: number, active: boolean) {
		try {
			const { data } = await updateReportSubscriptionMutation.mutate({
				inputs: {
					reportSubscriptionId,
					active,
				},
			})
			if (data) {
				const successHeading = active ? 'Report Subscription Activated' : 'Report Subscription Deactivated'
				const successMessageText = active ? 'Report Subscription has been activated successfully.' : 'Report Subscription has been deactivated successfully.'
				mediator.publish('showMessage', {
					heading: successHeading,
					message: successMessageText,
					type: 'success',
					time: false,
				})
				return true
			}
		} catch (err) {
			const error = err as Error
			console.error(err)
			const failedHeading = active ? 'Failed To Activate Report Subscription' : 'Failed To Deactivate Report Subscription'
			const failedMessageText = active ? 'Failed to activate the Report Subscription.' : 'Failed to deactivate the Report Subscription.'
			mediator.publish('showMessage', {
				heading: failedHeading,
				message: failedMessageText,
				type: 'danger',
				time: false,
			})
			return false
		}
		return false
	}

	async function testQuery(query: string) {
		const { data } = await testSelectQuery.fetch({
			variables: {
				query,
			},
		})
		if (!data) {
			throw new Error('No data returned from testSelectQuery')
		}
		return data.testSelectQuery ? 'True' : 'False'
	}

	async function generatePdfPreview({ name, type, parameters }: Parameters<ComponentProps<ReportSubscriptions>['generatePdfPreview']>[0]) {
		const { data } = await generateCrystalReport.mutate({
			reportJob: {
				name,
				parameters: Object.entries(parameters).map(([key, value]) => ({ key, value })),
				type,
				id: selectedReportSubscription?.reportId,
			},
		})
		if (!data) {
			throw new Error('No data returned from generateCrystalReport')
		}
		return data.generateCrystalReport
	}

	function onPageChange(event: ComponentEvents<ReportSubscriptions>['pageChange']) {
		asr.go(null, { pageNumber: event.detail.pageNumber }, { inherit: true })
	}

	function onShowOnlyMineChange(event: ComponentEvents<ReportSubscriptions>['showOnlyMine']) {
		asr.go(null, { showOnlyMine: event.detail }, { inherit: true })
	}

	const reportSubscriptionParametersQuery = graphql(`
		query ReportSubscriptionParameters($reportSubscriptionId: Int!) {
			reportSubscriptionParameters(reportSubscriptionId: $reportSubscriptionId) {
				items {
					reportParameterId
					value
					reportParameter {
						required
						type
						visible
						label
						name
						editability
						options {
							id
							label
							value
							isDefault
						}
					}
				}
			}
		}
	`)

	const generateCrystalReport = graphql(`
		mutation GenerateCrystalReportForSubscription($reportJob: GenerateCrystalReportInput!) {
			generateCrystalReport(input: $reportJob) {
				data
				mimeType
			}
		}
	`)

	const newReportSubscriptionMutation = graphql(`
		mutation CreateReportSubscription($inputs: NewReportSubscription!) {
			createReportSubscription(input: $inputs) {
				id
				createdByUserId
				createdByUser {
					id
					firstName
					lastName
				}
				sourceType
				destinationType
				destination
				title
				emailBody
				emailFrom
				attachmentName
				reportId
				nextInstance
				schedulePeriodType
				schedulePeriod
				active
				dateCreated
				comments
				sourceQuery
				parameterDateRange
				report {
					id
					name
					type
					category
					description
				}
				parameters {
					reportParameterId
					value
					reportParameter {
						required
						type
						visible
						label
						name
						editability
						options {
							id
							label
							value
						}
					}
				}
			}
		}
	`)

	const updateReportSubscriptionMutation = graphql(`
		mutation UpdateExistingReportSubscription($inputs: UpdateReportSubscription!) {
			updateReportSubscription(input: $inputs) {
				id
				createdByUserId
				createdByUser {
					id
					firstName
					lastName
				}
				sourceType
				destinationType
				destination
				title
				emailBody
				emailFrom
				attachmentName
				reportId
				nextInstance
				schedulePeriodType
				schedulePeriod
				active
				dateCreated
				comments
				sourceQuery
				parameterDateRange
				report {
					id
					name
					type
					category
					description
				}
				parameters {
					reportParameterId
					value
					reportParameter {
						required
						type
						visible
						label
						name
						editability
						options {
							id
							label
							value
						}
					}
				}
			}
		}
	`)

	const testSelectQuery = graphql(`
		query TestSelectQuery($query: String!) {
			testSelectQuery(query: $query)
		}
	`)
</script>

<ReportSubscriptions
	{groups}
	{printers}
	{userAccounts}
	{reportList}
	{timezone}
	{perPageCount}
	{currentPageNumber}
	{totalItemsCount}
	{showOnlyMine}
	sites={plants}
	{testQuery}
	{generatePdfPreview}
	{getReportParameters}
	{getSelectedReportSubscriptionParameters}
	{createReportSubscription}
	{updateReportSubscription}
	updateStatus={updateReportSubscriptionStatus}
	bind:reportSubscriptionList
	bind:selectedReportSubscription
	on:pageChange={onPageChange}
	on:showOnlyMine={onShowOnlyMineChange}
></ReportSubscriptions>
