export default {
    "name": "GenerateCrystalReport",
    "kind": "HoudiniMutation",
    "hash": "36efc34c9ea40b3ae684fa07f4592be782428a97b96267ab60450393e7cc17ba",

    "raw": `mutation GenerateCrystalReport($input: GenerateCrystalReportInput!) {
  generateCrystalReport(input: $input) {
    data
    mimeType
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "generateCrystalReport": {
                "type": "GenerateCrystalReportResponse",
                "keyRaw": "generateCrystalReport(input: $input)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "String",
                            "keyRaw": "data",
                            "visible": true
                        },

                        "mimeType": {
                            "type": "String",
                            "keyRaw": "mimeType",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "GenerateCrystalReportInput"
        },

        "types": {
            "ReportParameterInput": {
                "key": "String",
                "value": "String"
            },

            "GenerateCrystalReportInput": {
                "id": "Int",
                "name": "String",
                "parameters": "ReportParameterInput",
                "type": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=6bd963b68743e1f27fdcc4729d4eeb713c8c5ca0c9c09e27cb9712c8fcce4865";