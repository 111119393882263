export default {
    "name": "GetReports",
    "kind": "HoudiniQuery",
    "hash": "878cd7c60f4925d00bc2eabf59e0472c375b709d9379df60c7a779c3c1c87f1f",

    "raw": `query GetReports($reportsFilter: ReportsFilter) {
  reports(filter: $reportsFilter, pagination: {pageSize: 0, pageNumber: 1}) {
    items {
      id
      name
      type
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reports": {
                "type": "ReportsList",
                "keyRaw": "reports(filter: $reportsFilter, pagination: {pageSize: 0, pageNumber: 1})",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Report",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "reportsFilter": "ReportsFilter"
        },

        "types": {
            "ReportsFilter": {
                "type": "String",
                "userVisible": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=9a34b512ba2de33d70d34ed6dc845cba05928d4fceac7aa91258d752b0ed92af";