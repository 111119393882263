export default {
    "name": "GetEmailSuggestions",
    "kind": "HoudiniQuery",
    "hash": "52c98e3cc424d9d7615ac4faad66c8d3b28b97bf926dd52db97bfb5b493afef7",

    "raw": `query GetEmailSuggestions {
  getEmailSuggestions
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getEmailSuggestions": {
                "type": "String",
                "keyRaw": "getEmailSuggestions",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b86f8df8a58937c9a986b5fd05c4abb418662adecd0cf44fd083ac67331011c7";