export default {
    "name": "ReportQueueJobs",
    "kind": "HoudiniQuery",
    "hash": "cc7245c0e65bdd8b8a87f5b601ee1219a120e807f4f0d3d6259ab69262366d9e",

    "raw": `query ReportQueueJobs($pagination: PaginationOptions, $filter: ReportQueueFilter, $orderBy: [ReportQueueJobSort!]!) {
  reportQueueJobs(pagination: $pagination, filter: $filter, orderBy: $orderBy) {
    items {
      id
      reportId
      type
      name
      quantity
      dateCreated
      destinationType
      destination
      status
      executionTime
      notes
      error
      parameters {
        key: parameterName
        value
        id
      }
      createdByUser {
        name
        id
      }
    }
    pageInfo {
      pageNumber
    }
    totalItems
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reportQueueJobs": {
                "type": "ReportQueueJobList",
                "keyRaw": "reportQueueJobs(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "ReportQueue",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "reportId": {
                                        "type": "Int",
                                        "keyRaw": "reportId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "quantity": {
                                        "type": "Int",
                                        "keyRaw": "quantity",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "dateCreated": {
                                        "type": "Date",
                                        "keyRaw": "dateCreated",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "destinationType": {
                                        "type": "DestinationType",
                                        "keyRaw": "destinationType",
                                        "visible": true
                                    },

                                    "destination": {
                                        "type": "String",
                                        "keyRaw": "destination",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ReportQueueStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "executionTime": {
                                        "type": "Float",
                                        "keyRaw": "executionTime",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "notes": {
                                        "type": "String",
                                        "keyRaw": "notes",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "error": {
                                        "type": "String",
                                        "keyRaw": "error",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "parameters": {
                                        "type": "ReportQueueParameter",
                                        "keyRaw": "parameters",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "key": {
                                                    "type": "String",
                                                    "keyRaw": "key",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "createdByUser": {
                                        "type": "UserAccount",
                                        "keyRaw": "createdByUser",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "pageInfo": {
                            "type": "PageInfo",
                            "keyRaw": "pageInfo",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "totalItems": {
                            "type": "Int",
                            "keyRaw": "totalItems",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginationOptions",
            "filter": "ReportQueueFilter",
            "orderBy": "ReportQueueJobSort"
        },

        "types": {
            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            },

            "ReportQueueFilter": {
                "delayed": "Boolean",
                "status": "ReportQueueStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=bf522ec96b74e38176a5d54ade8e1f0d26f744f6e352fc184172e42b4f658c38";