import type { AppContext, ResolveParameters } from 'types/common'
import component from './Reports.svelte'

import { graphql } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import loadReports from 'utility/report-viewer-helper'
import makeFavoriteReportsStore from 'stores/favorite-reports'

type StateParameters = {
	selectedReportId: number | null
	selectedCategory: string | null
}
const settingsQuery = graphql(`
	query ReportSettings {
		settingValues {
			reportViewer {
				favoriteReportNames
			}
		}
	}
`)

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.reports',
		route: 'reports',
		defaultChild: 'preview',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, stateParameters: ResolveParameters<StateParameters>) {
			const selectedReportId = parseInt(stateParameters.selectedReportId, 10) || null
			const [reportList, settings] = await Promise.all([loadReports({ filter: { userVisible: true }, pagination: { pageNumber: 1, pageSize: 1000 } }), settingsQuery.fetch()])

			if (!reportList.length) {
				throw showErrorAndRedirect(mediator, 'Failed to load Report Viewer', 'No reports returned from server', { name: 'app' })
			}

			return {
				reportList,
				selectedReportId,
				selectedCategory: stateParameters.selectedCategory,
				favorites: makeFavoriteReportsStore(settings?.data?.settingValues.reportViewer.favoriteReportNames ?? ''),
			}
		},
	})
}
