export default {
    "name": "Reports",
    "kind": "HoudiniQuery",
    "hash": "4559d6686327d5c5e992ed89bc7c767091f1e58f3625e0eaf54fa8307f862fae",

    "raw": `query Reports($pagination: PaginationOptions, $filter: ReportsFilter) {
  reports(pagination: $pagination, filter: $filter) {
    items {
      id
      type
      category
      name
      userVisible
      description
      numberOfParameters
      averageExecutionTime
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reports": {
                "type": "ReportsList",
                "keyRaw": "reports(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Report",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "userVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "userVisible",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "numberOfParameters": {
                                        "type": "Int",
                                        "keyRaw": "numberOfParameters",
                                        "visible": true
                                    },

                                    "averageExecutionTime": {
                                        "type": "Int",
                                        "keyRaw": "averageExecutionTime",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginationOptions",
            "filter": "ReportsFilter"
        },

        "types": {
            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            },

            "ReportsFilter": {
                "type": "String",
                "userVisible": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1112b0b18e927c6e5d929d4d5e825a408f18cc398e529e5e8dac709aee35c740";