export default {
    "name": "CreateReportQueueJob",
    "kind": "HoudiniMutation",
    "hash": "5170be1c50a09b9df793e01a7c9050a3cd63dcafe2fe2772ad6781d39d3ee9a8",

    "raw": `mutation CreateReportQueueJob($input: NewReportQueue!) {
  createReportQueueJob(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createReportQueueJob": {
                "type": "ReportQueue",
                "keyRaw": "createReportQueueJob(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewReportQueue"
        },

        "types": {
            "NewReportQueueParameter": {
                "parameterName": "String",
                "value": "String"
            },

            "NewReportQueue": {
                "attachmentName": "String",
                "createdByUserId": "Int",
                "destination": "String",
                "destinationType": "ReportSubscriptionDestinationType",
                "emailBcc": "String",
                "emailBody": "String",
                "emailFrom": "String",
                "emailSubject": "String",
                "error": "String",
                "executionTime": "Float",
                "lastSentOn": "Date",
                "name": "String",
                "notes": "String",
                "parameters": "NewReportQueueParameter",
                "quantity": "Int",
                "reportId": "Int",
                "sourceQuery": "String",
                "sourceType": "SourceType",
                "status": "ReportQueueStatus",
                "title": "String",
                "type": "String",
                "waitUntil": "Date"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=fa5c32445f663a1cd0853bbcedf1d0f8c1e434b4393638febb0065dfa823e54b";