export default {
    "name": "ReportSettings",
    "kind": "HoudiniQuery",
    "hash": "4d022dcf1594fcc4b366e58e97e575820bafa91f0ae590586a98c3f9e38e219f",

    "raw": `query ReportSettings {
  settingValues {
    reportViewer {
      favoriteReportNames
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "settingValues": {
                "type": "SettingValues",
                "keyRaw": "settingValues",

                "selection": {
                    "fields": {
                        "reportViewer": {
                            "type": "ReportViewerSettingValues",
                            "keyRaw": "reportViewer",

                            "selection": {
                                "fields": {
                                    "favoriteReportNames": {
                                        "type": "String",
                                        "keyRaw": "favoriteReportNames",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b8ccc8a2cc87161b26f847c0e917cf08e628a2549b11b94fda8007a389aeb4bf";