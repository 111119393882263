import { graphql } from '$houdini'
import { type Writable, writable } from 'svelte/store'

interface InitArgs<T extends string | boolean | number = string> {
	category: string
	name: string
	/** The initial value of the store - the current value of the setting */
	initialValue: T
	marshal?: (value: T) => string | boolean | number
	unmarshal?: (value: string | boolean | number) => T
}

export type SettingValueStore<T extends string | number | boolean> = Omit<Writable<T>, 'update'>
/**
 * This function creates a store that will automatically update the setting value when the store's value is updated.
 * @param setting An object representing the setting
 * @returns A writable store that contains the setting value. It also has a teardown function that should be called when the store is no longer needed.
 */
export default function makeSettingValueStore<T extends string | boolean | number = string>(setting: InitArgs<T>): SettingValueStore<T> {
	const { subscribe, set: defaultSet } = writable<T>(setting.initialValue)

	function set(newValue: T) {
		defaultSet(newValue)
		saveSettingValue(setting, newValue).catch(err => console.error('Error setting setting value', err))
	}

	return {
		subscribe,
		set,
	}
}

async function saveSettingValue<T extends string | boolean | number>(setting: InitArgs<T>, newValue: T) {
	const valueToSave = setting.marshal ? setting.marshal(newValue) : newValue
	return await saveSettingMutation.mutate({
		input: {
			[setting.category]: {
				[setting.name]: valueToSave,
			},
		},
	})
}

const saveSettingMutation = graphql(`
	mutation SaveFavoriteReports($input: SetUserSettingInput!) {
		setUserSetting(input: $input)
	}
`)
